import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TableServerComponent } from '@app/modules/shared/components/table-server/table-server.component';
import { ColumnConfig } from '@app/modules/shared/models/server-pagination';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { FilterService } from '@app/modules/shared/services/filter.service';
import { QueryParamsService } from '@app/modules/shared/services/queryParams.service';
import { ServerPaginationService } from '@app/modules/shared/services/server-pagination.service';
import { TranslateService } from '@ngx-translate/core';
import { Customer, CustomerFilters } from '../models/customer';
import { CustomersService } from '../services/customers.service';

@Component({
  selector: 'tu-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  providers: [CustomersService],
})
export class CustomersComponent implements OnInit {
  constructor(
    private customersService: CustomersService,
    private filterService: FilterService,
    private authService: AuthService,
    private readonly translate: TranslateService,
    private readonly forms: UntypedFormBuilder,
    public serverPaginationService: ServerPaginationService,
    private queryParamsService: QueryParamsService
  ) {}

  @ViewChild('table', { static: true }) table: TableServerComponent;

  get shouldShowCustomerSearch(): boolean {
    return window['__CONFIG__']?.features?.customerSearch ?? true;
  }

  public filters: CustomerFilters = {};

  public readonly filtersForm = this.forms.group({
    attribute: [null],
    attribute_search: [null],
    virtual_provider: [null],
  });

  public computeLink(customer: Customer) {
    return `/customers/${customer.customer_id}`;
  }

  public get columnsConfig(): ColumnConfig<Customer>[] {
    return [
      {
        key: 'customer_id',
        label: this.translate.instant('pages.customer.id'),
        type: 'text',
      },
      {
        key: 'picture',
        label: this.translate.instant('pages.customer.avatar'),
        type: 'image',
        modifier(_, row) {
          if (row.authLastAsGuest) {
            return '/assets/img/user-anonymous.svg';
          }
        },
        config: {
          alt: (_, row) => row.fullname,
          src(value: string) {
            if (!value) return '/assets/img/user.svg';
            return value;
          },
        },
      },
      {
        key: 'firstname',
        label: this.translate.instant('pages.customer.firstname'),
        type: 'text',
      },
      {
        key: 'lastname',
        label: this.translate.instant('pages.customer.lastname'),
        type: 'text',
      },
      {
        key: 'email',
        label: this.translate.instant('otherslabels.col_mail'),
        type: 'link',
        config: {
          href: (_, row) => `mailto:${row.email}`,
        },
      },
      {
        key: 'date',
        label: this.translate.instant('pages.customer.signin_date'),
        type: 'date',
        config: {
          format: 'dd/MM/yyyy',
        },
      },
      {
        key: 'authLastAsGuest',
        label: this.translate.instant('pages.customer.guest_mode'),
        type: 'badge',
        modifier(authLastAsGuest: boolean) {
          return authLastAsGuest ? 'YES' : 'NO';
        },
        config: {
          YES: { type: 'information', label: this.translate.instant('otherslabels.yes') },
          NO: { type: 'information', label: this.translate.instant('otherslabels.no') },
        },
      },
      {
        key: 'order_count',
        label: this.translate.instant('pages.customer.sales_number'),
        type: 'text',
      },
      {
        key: 'parent',
        label: this.translate.instant('pages.customer.main_account'),
        type: 'link',
        modifier: (parent: Customer) => {
          if (!parent) return this.translate.instant('otherslabels.main_account');
          return `${parent.firstname} ${parent.lastname}`;
        },
        config: {
          href: (_, row) => {
            if (row.parent) return `/customers/${row.parent.customer_id}`;
          },
        },
      },
      {
        key: 'network_ids',
        label: this.translate.instant('otherslabels.col_networks'),
        type: 'text',
        modifier: (ids: number[]) => {
          const networks = ids
            .map((id) => {
              const network = this.authService.networks.find((network) => +network.id === +id);

              return network?.name;
            })
            .filter(Boolean);

          return networks.join(', ');
        },
        isDisplayed: () => this.authService.networks.length > 1,
      },
      {
        key: 'virtual_provider',
        label: this.translate.instant('pages.customer.registration_platform'),
        type: 'image',
        modifier: (_, customer) => {
          if (customer.universal || !customer.network_ids.length) return 'tixipass';
          if (customer.provider?.toLowerCase().includes('instantsystem')) return 'instantsystem';
          return customer.provider;
        },
        config: {
          alt: (value: string) => value,
          src(value: string) {
            switch (value) {
              case 'tixipass':
                return '/assets/img/logo_tixipass.png';
              case 'sncf':
                return '/assets/img/providers/sncf.png';
              case 'smt_nfc':
                return '/assets/img/providers/smt_nfc.png';
              case 'modalis':
                return '/assets/img/providers/modalis.png';
              case 'instantsystem':
                return '/assets/img/providers/instant_system.png';
              default:
                return '';
            }
          },
        },
        isDisplayed: () => this.authService.networks.some((network) => network.universal),
      },
    ];
  }

  public computeFilters() {
    const globalFilters = this.filterService.filters;
    const { virtual_provider, attribute, attribute_search } = this.filtersForm.value;

    this.filters = {
      network_id: globalFilters.network,
      from: globalFilters.from,
      to: globalFilters.to,
      query: globalFilters.query,
      provider: virtual_provider,
      attribute,
      attribute_search,
    };

    this.queryParamsService.localFilterParams = this.filtersForm.value;
  }

  public attributes;

  get selectedAttribute() {
    const hasAttributes = Boolean(this.attributes);
    const hasSelectedAttribute = Boolean(this.filtersForm.value.attribute);

    if (!hasAttributes || !hasSelectedAttribute) return false;

    const attribute = this.attributes.find(
      (attribute) => +attribute.id === +this.filtersForm.value.attribute
    );

    return {
      ...attribute,
      values: attribute?.values?.sort((a: string, b: string) => a.localeCompare(b)) ?? undefined,
    };
  }

  get exportInformation() {
    const hasCheckedRows = this.table.selectedRows.length;

    const checkedRowsIds = hasCheckedRows
      ? this.table.selectedRows.map((row) => row.customer_id)
      : [];

    const customersFilters = this.filters;
    const hasSelectedAttribute = customersFilters.attribute && customersFilters.attribute_search;

    const filters = {};

    if (customersFilters.provider) filters['provider'] = customersFilters.provider;

    if (hasSelectedAttribute) {
      filters['attributeId'] = customersFilters.attribute.toString();
      filters['attributeValue'] = customersFilters.attribute_search.toString();
    }

    return {
      exportId: 'cms-customers-v2',
      checkedRowsIds: checkedRowsIds,
      hasCheckedAll: this.table.allSelected,
      filters: filters,
    };
  }

  ngOnInit() {
    if (!this.shouldShowCustomerSearch) {
      this.filterService.reset();
    }

    const params = this.queryParamsService.localFilterParams;

    const formParams: Record<string, string> = {};

    for (const property of Object.keys(this.filtersForm.value)) {
      formParams[property] = params[property] || null;
    }

    this.filtersForm.setValue(formParams);

    this.computeFilters();

    this.filtersForm.controls.attribute.valueChanges.subscribe((value) => {
      const attribute = this.attributes.find((a) => a.id === value);
      this.filtersForm.controls.attribute_search.setValue(attribute?.values?.[0] || null);
    });

    this.customersService.getAttributes().then((attributes) => {
      this.attributes = attributes;
    });
  }
}
