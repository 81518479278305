import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CustomerFusion,
  Customer,
  CustomerFusionConfiguration,
  Attribute,
} from '@app/modules/customers/models/customer';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, Notification } from 'angular2-notifications';
import { debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { CustomersService, $$GlobalAttribute } from '../../../services/customers.service';
import { Router } from '@angular/router';

export interface DialogData {
  customer: Customer;
  attributes: {
    global: $$GlobalAttribute[];
    customer: Attribute[];
  };
}
@Component({
  selector: 'tu-fusion-modal',
  templateUrl: './fusion-modal.component.html',
})
export class FusionModalComponent implements OnInit {
  public customer: Customer = null;
  public attributes: {
    global: $$GlobalAttribute[];
    customer: Attribute[];
  } = null;

  public search = new UntypedFormControl();
  public fusionEmail = new UntypedFormControl();
  public fusionAddress = new UntypedFormControl();
  public fusionPhone = new UntypedFormControl();
  public fusionBirthdate = new UntypedFormControl();
  public fusionAttributes: { [key: string]: UntypedFormControl }[] = [];

  public filteredCustomers: CustomerFusion[] = [];
  public selectedCustomers: CustomerFusion[] = [];

  public customerEmails: { label: string; value: any }[] = [];
  public customerAddresses: { label: string; value: any }[] = [];
  public customerPhones: { label: string; value: any }[] = [];
  public customerBirthdates: { label: string; value: any }[] = [];
  public customerAttributes: {
    key: string;
    id: string;
    label: string;
    values: { value: number; label: any }[];
  }[] = [];

  public loading = false;
  public notificationOptions = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  public get canGoNextStep() {
    return this.selectedCustomers.length > 0;
  }

  public get customersLabel() {
    let label = [];
    for (const customer of this.selectedCustomers) {
      label.push(`${customer.id} (${customer.firstName || ''} ${customer.lastName || ''})`);
    }
    return label.join(', ');
  }

  public get recapLabel() {
    return this.translate.instant('pages.customer_details.fusion_modal_users_recap', {
      customersLabel: this.customersLabel,
      id: this.customer.customer_id,
      customerLabel: `${this.customer.firstname} ${this.customer.lastname}`,
    });
  }

  constructor(
    public modalRef: MatDialogRef<FusionModalComponent>,
    private customersService: CustomersService,
    private translateService: TranslateService,
    private notification: NotificationsService,
    private translate: TranslateService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.customer = data.customer;
    this.attributes = data.attributes;
  }

  ngOnInit() {
    this.search.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((search) => {
      this.filteredCustomers = [];
      if (!search) return;

      this.customersService
        .getFusionList(this.data.customer.customer_id, search)
        .then(({ response }) => {
          if (response.errorMessage) {
            throw new Error(response.errorMessage);
          }

          this.filteredCustomers = response.customers.filter((customer) => {
            return (
              this.selectedCustomers.filter(
                (selectedCustomer) => selectedCustomer.id === customer.id
              ).length === 0
            );
          });

          if (this.filteredCustomers.length === 0) {
            this.notification.warn(
              this.translate.instant('pages.customer_details.fusion_modal_no_customer_search'),
              search
            );
          }
        })
        .catch((reason) => {
          this.filteredCustomers = [];
          const error = reason?.error?.message || reason;
          this.notification.error(
            this.translate.instant('pages.customer_details.fusion_modal_error_on_search'),
            error
          );
          console.error(reason);
        });
    });
  }

  public closeModal() {
    this.modalRef.close();
  }

  public selectedCustomer(event: MatAutocompleteSelectedEvent): void {
    this.selectedCustomers.push(event.option.value);
    this.search.setValue(null);
  }

  public addCustomer(customer: CustomerFusion) {
    const customerMatching = this.selectedCustomers.find((customerA) => {
      customerA.id === customer.id;
    });
    if (!customerMatching) {
      return;
    }
    this.selectedCustomers.push(customer);
    this.search.setValue(null);
  }

  public removeCustomer(customer: CustomerFusion) {
    this.selectedCustomers = this.selectedCustomers.filter(
      (customerA) => customerA.id !== customer.id
    );
  }

  public generateFusionLists() {
    //Order selectedCustomer by last updatedDate DESC
    this.selectedCustomers.sort((customerA, customerB) => {
      return customerA.updatedAt < customerB.updatedAt ? 1 : -1;
    });
    //Generate lists
    this.customerEmails = this._generateList('email');
    this.customerAddresses = this._generateList('address');
    this.customerPhones = this._generateList('phone');
    this.customerBirthdates = this._generateList('birthdate');
    this.customerAttributes = this._generateAttributesList();
    this._updateAttributesFormControl();

    //Default value on select
    this.fusionEmail.setValue(this.customerEmails[0]?.value || null);
    this.fusionAddress.setValue(this.customerAddresses[0]?.value || null);
    this.fusionPhone.setValue(this.customerPhones[0]?.value || null);
    this.fusionBirthdate.setValue(this.customerBirthdates[0]?.value || null);
  }

  private _generateList(listSlug: 'email' | 'address' | 'phone' | 'birthdate') {
    type CustomerInfoList = { id: number; value: any };
    const customerInfos: CustomerInfoList[] = [];

    //Current customer value
    const currentCustomerData: CustomerInfoList = {
      id: parseInt(this.customer.customer_id, 10),
      value: null,
    };
    switch (listSlug) {
      case 'birthdate':
        currentCustomerData.value = this.customer.birthday;
        break;
      default:
        currentCustomerData.value = this.customer[listSlug];
    }

    if (currentCustomerData.value) {
      customerInfos.push(currentCustomerData);
    }
    //Fusion customers values
    for (const customer of this.selectedCustomers) {
      if (!customer[listSlug]) continue;
      const customerData: CustomerInfoList = { id: customer.id, value: customer[listSlug] };
      const valueMatching = customerInfos.find((customer) => customer.value === customerData.value);
      if (customerData.value && !valueMatching) {
        customerInfos.push(customerData);
      }
    }

    //Transform into list object (label, value)
    const options = customerInfos
      .map((customerData) => {
        let label = customerData.value;
        if (!label) return;
        switch (listSlug) {
          case 'address':
            //Current customer address
            if (label?.country !== undefined) {
              label = [label.streetNumber, label.route, label.zipCode, label.city, label.country]
                .map((string) => string || '')
                .join(' ')
                .trim();
            }
            //Fusion customer address
            else {
              label = [label.lines[0], label.zip, label.city, label.state]
                .map((string) => string || '')
                .join(' ')
                .trim();
            }
            if (label.length === 0) {
              return;
            }
            break;
          case 'birthdate':
            const birthday = new Date(label);
            label = birthday.toLocaleDateString();
            break;
          default:
            break;
        }

        return {
          value: customerData.id,
          label,
        };
      })
      .filter(Boolean);
    if (options.length === 0)
      return [
        {
          value: this.customer.customer_id,
          label: this.translate.instant('pages.customer_details.fusion_modal_no_value'),
        },
      ];
    return options;
  }

  public async fusionCustomers() {
    this.loading = true;
    const customerId = parseInt(this.customer.customer_id, 10);

    let notification: Notification = null;
    let redirect = true;
    for (const fusionCustomer of this.selectedCustomers) {
      let fusionConfiguration: CustomerFusionConfiguration = {
        email: parseInt(this.fusionEmail.value, 10) === fusionCustomer.id ? 'FORMER' : 'RECIPIENT',
        address:
          parseInt(this.fusionAddress.value, 10) === fusionCustomer.id ? 'FORMER' : 'RECIPIENT',
        phone: parseInt(this.fusionPhone.value, 10) === fusionCustomer.id ? 'FORMER' : 'RECIPIENT',
        birthdate:
          parseInt(this.fusionBirthdate.value, 10) === fusionCustomer.id ? 'FORMER' : 'RECIPIENT',
      };
      if (this.fusionAttributes.length) {
        let attributeValues: Record<string | number, 'RECIPIENT' | 'FORMER'> = {};
        for (const attributeId in this.fusionAttributes) {
          const value = this.fusionAttributes[attributeId].value as unknown as string; //FormControl<string> error on def Oo
          attributeValues = {
            ...attributeValues,
            [parseInt(attributeId, 10)]:
              parseInt(value, 10) === fusionCustomer.id ? 'FORMER' : 'RECIPIENT',
          };
        }
        fusionConfiguration = {
          ...fusionConfiguration,
          attributeValues,
        };
      }

      await this.customersService
        .fusionCustomers(customerId, fusionCustomer.id, fusionConfiguration)
        .then((success) => {
          if (success) {
            notification = this.notification.success(
              this.translate.instant('pages.customer_details.fusion_modal_user_ok', {
                id: fusionCustomer.id,
              })
            );
          } else {
            notification = this.notification.error(
              this.translate.instant('pages.customer_details.fusion_modal_user_ko', {
                id: fusionCustomer.id,
              })
            );
            redirect = false;
          }
        })
        .catch((error) => {
          notification = this.notification.error(
            this.translate.instant('pages.customer_details.fusion_modal_user_ko', {
              id: fusionCustomer.id,
            })
          );
          console.error('Fusion error', error);
          redirect = false;
        });
    }

    await firstValueFrom(notification.timeoutEnd);
    this.loading = false;

    if (redirect) {
      this.modalRef.close();
      this.router.navigate(['/customers/', this.customer.customer_id]);
    }
  }

  private _generateAttributesList() {
    const attributesValues: {
      key: string;
      id: string;
      label: string;
      values: { value: number; label: any }[];
    }[] = [];

    //Global / Customer attributes
    for (const attribute of this.attributes.global) {
      const id = attribute.id;
      const key = attribute.key;
      const customerValue = this.attributes.customer.find(
        (customerAttribute) => customerAttribute.key === key
      );
      attributesValues.push({
        id,
        key,
        label: attribute.label,
        values: [
          {
            value: parseInt(this.customer.customer_id, 10),
            label: customerValue?.value || null,
          },
        ],
      });
    }

    //Fusionned customer attributes
    for (const customer of this.selectedCustomers) {
      for (const attributes of customer.attributeValues) {
        const attributeValue = attributesValues.find(
          (attributesValue) => attributesValue.key === attributes.attributeKey
        );
        if (attributeValue) {
          attributeValue.values.push({
            value: customer.id,
            label: attributes.value,
          });
          attributeValue.values = attributeValue.values.filter(Boolean);
        }
      }
    }

    const attributesOptions = attributesValues.map((attribute) => {
      if (attribute.values.length !== 1 || attribute.values[0].label) return attribute;
      return {
        ...attribute,
        values: [
          {
            value: attribute.values[0].value,
            label: this.translate.instant('pages.customer_details.fusion_modal_no_value'),
          },
        ],
      };
    });

    return attributesOptions;
  }

  private _updateAttributesFormControl() {
    this.fusionAttributes = [];
    for (const attribute of this.customerAttributes) {
      const attributeFormControl = new UntypedFormControl(attribute.values[0].value);
      this.fusionAttributes[attribute.id] = attributeFormControl;
    }
  }
}
