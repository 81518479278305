import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { FilterService } from '@app/modules/shared/services/filter.service';
import { CacheManager } from '@app/utils/cache-manager';
import { environment as env } from '@env/environment';
import { firstValueFrom, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DeleteUserResponse, User } from '../models/user';
import { GenericResponse } from '@app/modules/shared/models/generic-response';

@Injectable()
export class UsersService {
  private cache = new CacheManager('users');

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private filters: FilterService
  ) { }

  public getUsers(): Observable<User[]> {
    let version = this.cache.getVersion();

    return of([null]).pipe(
      switchMap(() =>
        this.http.get<any>(env.config.feedRoot + `User/list.json?v=${version}`, {
          params: this.filters.filtersWithID,
        })
      ),

      map(({ response: r }) => {
        return r.users as User[];
      })
    );
  }

  public getUserDetail(id: number): Observable<User> {
    let version = this.cache.getVersion();

    return this.http.get<any>(env.config.feedRoot + `User/details.json?id=${id}&v=${version}`).pipe(
      map(({ response: r }) => {
        return r.user as User;
      })
    );
  }

  public createUser(user: User): Observable<{ id: number }> {
    return this.http.post<any>(env.config.feedRoot + `User/createUser.json`, user).pipe(
      map(({ response: r }) => {
        if (r.errorMessage) {
          if (r.errorCode !== null) throw new Error(r.errorCode);
        } else {
          this.cache.incrementVersion();
          return r.user as { id: number };
        }
      })
    );
  }

  public editUser(user: User): Observable<{ id: number }> {
    return this.http.post<any>(env.config.feedRoot + `User/editUser.json`, user).pipe(
      map(({ response: r }) => {
        if (r.errorMessage) {
          if (r.errorCode !== null) throw new Error(r.errorCode);
        } else {
          this.cache.incrementVersion();
          return r as { id: number };
        }
      })
    );
  }

  public deleteUser(id: string) {
    return this.http
      .delete<DeleteUserResponse>(`${env.config.feedRoot}User/deleteUser.json`, {
        params: { id },
      })
      .pipe(
        map(({ response: r }) => {
          return r;
        })
      );
  }

  public forceAuthSecondFactor(userIds: number[]): Promise<{ success: boolean }> {
    return firstValueFrom(
      this.http
        .post<GenericResponse<{ success: boolean }>>(
          env.config.feedRoot + `User/forceAuthSecondFactor.json`,
          {
            userIds: JSON.stringify(userIds),
          }
        )
        .pipe(map(({ response }) => response))
    );
  }
}
