export interface Network {
  id: string;
  name: string;
  lat: number;
  lng: number;
  universal: boolean;
  od: boolean;
  agency_key: string;
  payment_company: string;
  currency?: string;
  defaultLocale?: string;
  /**
   * @deprecated
   * This field doesn't exist in the two API calls we use
   * to fill the `auth.networks` array. It was only existing in
   * the `/login` which is is called in another authentication service now.
   *
   * To bypass this limitation we added the information in the `data` of networks.
   */
  tap_channel: null | string | number;
  locales?: string[];
  offlineEnabled: boolean;
  hasTransferableTickets: boolean;
  hasPaymentPreauthorization: boolean;
  hasMediaSignin: boolean;
  timezone?: string;
  data?: any;
  hasReservation?: boolean;
  attestationProviderId?: number;
  features: NetworkFeature[];
}

export interface NetworkFeature {
  id: number;
  handle: NetworkFeatureHandle;
  createdAt: string;
}

export enum NetworkFeatureHandle {
  PROFILING = 'PROFILING',
  PHONE_MANDATORY = 'PHONE_MANDATORY',
  MULTIPLE_PAYMENT = 'MULTIPLE_PAYMENT',
  SHIPPING = 'SHIPPING',
  SAVE_CARDS = 'SAVE_CARDS',
  QR_CODE_AVAILABLE = 'QR_CODE_AVAILABLE',
  QR_CODE_REQUIRED = 'QR_CODE_REQUIRED',
  OD = 'OD',
  USE_PREAUTHORIZATION = 'USE_PREAUTHORIZATION',
  PAYMENT_PREAUTHORIZATION = 'PAYMENT_PREAUTHORIZATION',
  USE_MAILING_PLATFORM = 'USE_MAILING_PLATFORM',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  OFFLINE = 'OFFLINE',
  ORDER_CUSTOMER_FAMILY_ONLY = 'ORDER_CUSTOMER_FAMILY_ONLY',
  GUEST_ORDERS = 'GUEST_ORDERS',
  TRANSFERABLE_TICKETS = 'TRANSFERABLE_TICKETS',
  MEDIA_SIGNIN = 'MEDIA_SIGNIN',
  INSPECTION_SESSIONS = 'INSPECTION_SESSIONS',
  MEDIA_TUNNEL = 'MEDIA_TUNNEL',
  MEDIA_TUNNEL_PAIRING = 'MEDIA_TUNNEL_PAIRING',
  RESERVATION = 'RESERVATION',
  VIGNETTE = 'VIGNETTE',
}