import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment as env } from '@env/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class PermissionsService {
  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {}

  public getRoles() {
    return this.http.get<any>(env.config.feedRoot + 'Permissions/getRoles.json').pipe(
      map(({ response: r }) => {
        return r.roles;
      })
    );
  }
}
