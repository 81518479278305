import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Option } from '@app/modules/shared/components/modal/modal.component';
import { CommentFormValues } from '@app/modules/shared/models/comment';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { heroicons } from '@assets/icons/heroicons';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { firstValueFrom } from 'rxjs';
import { Comment } from '../../models/comment';
import { Item } from '../../models/item';
import {
  Order,
  OrderProcessStatus,
  OrderStatus,
  OrderDiscountType,
  OrderDiscount,
  Checkout,
  SchoolOrderArticle,
  Trip,
  ArticleBooking,
} from '../../models/order';
import { OrdersService } from '../../services/orders.service';
import { match } from 'ts-pattern';
import { ModalService } from '@app/modules/shared/services/modal.service';

@Component({
  selector: 'tu-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  providers: [OrdersService],
  encapsulation: ViewEncapsulation.None,
})
export class OrderDetailComponent implements OnInit, AfterViewChecked {
  @ViewChild('scrollBottom', { static: false }) private myScrollContainer: ElementRef;

  private version = 0; // No cache manager, because old repeatWhen don't increment the cache version
  private initialValues = {};

  public OrderStatus = OrderStatus;
  public OrderProcessStatus = OrderProcessStatus;

  // Provide access to the heroicons to the HTML template
  public heroicons = heroicons;

  private id = +this.route.snapshot.params['id'];

  public edit = {};
  public order: Order;
  public fieldForm: UntypedFormGroup;
  public articles: SchoolOrderArticle[] = [];

  get curr() {
    if (!this.order) return 'EUR';
    if (!this.authService.networks) return 'EUR';
    const network = this.authService.networks.find((n) => +n.id === +this.order.network_id);
    return network?.currency ?? 'EUR';
  }

  public comments: Comment[];
  public user_id = this.authService.user_id;
  public datePipeFormat: any;
  public zeroVat = true;
  public vats: {} = {};
  public entries = Object.entries;

  public get hasClickAndCollectItems(): boolean {
    return this.order?.items?.some((item) => item.support === 'COLLECT') ?? false;
  }

  public get hasHomeDeliveryItems(): boolean {
    return this.order?.items?.some((item) => item.support === 'SHIPPING') ?? false;
  }

  public handleOrderAction(event: Event) {
    const select = event.currentTarget;
    const isHTMLSelectElement = select instanceof HTMLSelectElement;

    if (!isHTMLSelectElement) {
      (select as HTMLSelectElement).value = 'NOOP';
      return this.notification.error(this.translate.instant('otherslabels.generic_error'));
    }

    const value = (select as HTMLSelectElement).value;
    if (value === 'NOOP') return;

    const email = this.order?.user?.email ?? '';

    const orderId = this.order?.order_identifier ?? '';

    const message = match(value)
      .with('SHIPPING', () => {
        return {
          title: this.translate.instant('pages.order_details.mail_shipping_title'),
          body: [
            this.translate.instant('pages.order_details.mail_shipping_line_1'),
            ``,
            this.translate.instant('pages.order_details.mail_shipping_line_2', { nb: orderId }),
            `${this.translate.instant(
              'pages.order_details.mail_shipping_line_3'
            )} : <${this.translate.instant('pages.order_details.mail_shipping_to_complete')}>.`,
            `${this.translate.instant(
              'pages.order_details.mail_shipping_line_4'
            )} : <${this.translate.instant('pages.order_details.mail_shipping_to_complete')}>.`,
            ``,
            `${this.translate.instant('pages.order_details.mail_shipping_line_5')},`,
            `<${this.translate.instant('pages.order_details.mail_shipping_to_complete')}>`,
          ].join('%0D%0A'),
        };
      })
      .with('COLLECT', () => {
        return {
          title: `${this.translate.instant('pages.order_details.mail_cc_title')},`,
          body: [
            this.translate.instant('pages.order_details.mail_shipping_line_1'),
            ``,
            this.translate.instant('pages.order_details.mail_shipping_line_2', { nb: orderId }),
            this.translate.instant('pages.order_details.mail_cc_line_3'),
            ``,
            `<${this.translate.instant('pages.order_details.mail_shipping_to_complete')}>.`,
            ``,
            `${this.translate.instant('pages.order_details.mail_shipping_line_5')},`,
            `<${this.translate.instant('pages.order_details.mail_shipping_to_complete')}>`,
          ].join('%0D%0A'),
        };
      })
      .run();

    const anchor = Object.assign(document.createElement('a'), {
      target: '_blank',
      href: `mailto:${email}?subject=${message.title}&body=${message.body}&content-type=text/html`,
    });

    document.body.appendChild(anchor);
    anchor.click();

    (select as HTMLSelectElement).value = 'NOOP';

    anchor.remove();
  }

  statusSelectOptions: Option[] = [
    { label: 'pages.order_details.completed', value: OrderStatus.completed },
    { label: 'pages.order.preauthorized', value: OrderStatus.preauthorized },
    { label: 'pages.order_details.pending', value: OrderStatus.pending },
    { label: 'pages.order_details.canceled', value: OrderStatus.canceled },
    { label: 'pages.order_details.error', value: OrderStatus.error },
    { label: 'pages.order.regularization', value: OrderStatus.regularization },
    { label: 'pages.order.waiting_for_payment', value: OrderStatus.waiting_for_payment },
    { label: 'pages.order.to_refund', value: OrderStatus.to_refund },
    { label: 'pages.order.refunded', value: OrderStatus.refunded },
    { label: 'pages.order.default_payment', value: OrderStatus.defaultPayment },
  ];
  selectedStatus: any;

  processStatusSelectOptions: Option[] = [
    { label: 'otherslabels.unspecified', value: 'NULL' },
    { label: 'pages.order_details.processed', value: OrderProcessStatus.processed },
    { label: 'pages.order_details.to_process', value: OrderProcessStatus.to_process },
    { label: 'pages.order_details.processing', value: OrderProcessStatus.processing },
  ];
  selectedProcessStatus: any;

  paymentMethods = {
    CARD: { type: 'info', label: 'pages.order.card' },
    TRANSFER: { type: 'warning', label: 'pages.order.transfer' },
    DEPOSIT: { type: 'success', label: 'pages.order.deposit' },
    CASH: { type: 'success', label: 'pages.order.cash' },
  };

  public notificationOptions = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  public checkouts: Checkout[] = [];

  // Get the latest checkout based on `createdAt` date
  public get latestCheckout(): Checkout | null {
    if (!this.checkouts?.length) return null;

    return this.checkouts
      .filter((checkout) => {
        // We only want to keep checkout from on operator
        return checkout.initiatorType === 'OPERATOR';
      })
      .reduce((latest, checkout) => {
        if (!latest) return checkout;

        return new Date(checkout.createdAt) > new Date(latest.createdAt) ? checkout : latest;
      });
  }

  public get showFooterHeader(): boolean {
    return (
      [OrderStatus.preauthorized, OrderStatus.completed].includes(this.order.status) &&
      this.order.process_status !== 'NULL'
    );
  }

  constructor(
    private orderService: OrdersService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private notification: NotificationsService,
    private translate: TranslateService,
    private authService: AuthService,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    await this.fetchOrderDetails();
    this.translate.onLangChange.subscribe(() => this.setTranslation());
    this.datePipeFormat = this.translate.instant('date_format.date_format');
  }

  async fetchOrderDetails() {
    // TODO: Use getOrderDetails instead of getLegacyOrderDetails -> Currently missing infos in getOrderDetails
    this.orderService.getLegacyOrderDetails(this.id).then((order) => {
      const group = {};

      for (const item of order.items || []) {
        if (!item.fields.length) continue;

        const tmp = {};

        for (const field of item.fields) {
          tmp[field.field_id] = new UntypedFormControl(
            field.value || field.value_id,
            !!+field.mandatory ? Validators.required : []
          );
        }

        group[item.id] = this.fb.group(tmp);
      }

      /**
       * [
       *   {
       *     "vat": "10.00",
       *     "price": 0.1
       *   }
       * ]
       *
       * ===>
       *
       * {
       *    "10.00": 0.1
       * }
       */
      const vats = order.vats.reduce(
        (vats, { vat, price }) => ({ ...vats, [vat]: (vats[vat] || 0) + price }),
        {}
      );

      const hasVatsOverZero = Object.keys(vats).some((vat) => parseFloat(vat) > 0);

      this.order = order;
      this.selectedStatus = order.status;
      this.selectedProcessStatus = order.process_status;

      this.vats = vats;
      this.zeroVat = !hasVatsOverZero;

      this.fieldForm = this.fb.group(group);
      this.initialValues = this.fieldForm.value;
    });

    this.orderService.getOrderDetails(this.id).then((order) => {
      this.checkouts = order.checkouts;
      this.articles = order.articles;
    });

    firstValueFrom(this.orderService.getOrderComments(this.id, this.version)).then((comments) => {
      this.comments = comments;
    });
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  public getVats(o: Order): Array<string> {
    return Object.keys(o.vats);
  }

  public getValue(values: any[], id: any) {
    const option = values.find((val: { id: any }) => val.id === id);
    return option ? option.value : '-';
  }

  public formatFieldValue(type: 'DATE' | 'INPUT', value: string) {
    if (type === 'INPUT') return value.trim();

    try {
      return new Date(value).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    } catch {
      return value.trim();
    }
  }

  public getPaymentMethodConfig(paymentMethod) {
    const configs = {
      CARD: { type: 'information', label: this.translate.instant('pages.order.card') },
      TRANSFER: { type: 'warning', label: this.translate.instant('pages.order.transfer') },
      DEPOSIT: { type: 'success', label: this.translate.instant('pages.order.deposit') },
      CASH: { type: 'success', label: this.translate.instant('pages.order.cash') },
    };
    return configs[paymentMethod];
  }

  public getPaymentMethodLabel(paymentMethod: string) {
    const paymentMethodConfig = this.paymentMethods[paymentMethod];

    return paymentMethodConfig?.label;
  }

  public getPaymentMethodCssClass(paymentMethod: string) {
    const paymentMethodConfig = this.paymentMethods[paymentMethod];

    return paymentMethodConfig?.type;
  }

  public async saveEdition(fieldItem) {
    const fields = this.fieldForm.controls[fieldItem.id].value;

    try {
      for (const [field, value] of Object.entries(fields)) {
        const articleId = fieldItem.fields.find((articleField) => {
          return articleField.field_id === field;
        }).order_item_id;

        await this.orderService.updateArticleFieldValue(
          this.order.id,
          articleId,
          fieldItem.id,
          field,
          value
        );
      }

      let i = this.order.items.findIndex((item) => item.id === fieldItem.id);

      if (typeof i !== 'undefined') {
        this.order.items[i].fields.forEach((field, f) => {
          if (field.type === 'SELECT') {
            this.order.items[i].fields[f].value_id =
              this.fieldForm.value[fieldItem.id][field.field_id];
          } else {
            this.order.items[i].fields[f].value =
              this.fieldForm.value[fieldItem.id][field.field_id];
          }
        });
        this.edit[fieldItem.id] = false;
      }
    } catch {
      this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
    }
  }

  public cancelEdition(item_id: string) {
    this.fieldForm.controls[item_id].reset(this.initialValues[item_id]);
    this.edit[item_id] = false;
  }

  public isInvoiceDownloading = false;

  public async printOrder(options: { legacy: boolean; version?: string } = { legacy: false }) {
    const anchor = document.createElement('a');

    try {
      this.isInvoiceDownloading = true;

      const orderId = options.legacy ? this.order.order_identifier : this.order.id;
      const invoiceFile = await firstValueFrom(this.orderService.getOrderInvoice(orderId, options));

      Object.assign(anchor, {
        style: `display: none;`,
        href: URL.createObjectURL(invoiceFile),
        download: `Commande_N°${this.order.order_identifier}.pdf`,
      });

      document.body.appendChild(anchor);
      anchor.click();

      this.notification.success(
        this.translate.instant('pages.order_details.invoice_download_success')
      );
    } catch (error) {
      console.error(error);
      this.notification.error(this.translate.instant(`otherslabels.notif_download_ko`));
    } finally {
      anchor.remove();
      this.isInvoiceDownloading = false;
    }
  }

  public async updateStatus(status: OrderStatus) {
    const response$ = this.orderService.updateStatus(this.order.id, status);

    const response = await firstValueFrom(response$);
    this.order.status = response.status;

    return response.status;
  }

  public updateProcessStatus(process_status: OrderProcessStatus) {
    this.orderService.updateProcessStatus(Number(this.order.id), process_status).subscribe((r) => {
      this.order.process_status = r.process_status;
      this.notification.info(this.translate.instant(`otherslabels.update_done`));
    });
  }

  private setTranslation() {
    setTimeout(() => {
      this.datePipeFormat = this.translate.instant('date_format.date_format');
    });
  }

  public getCssClass(status: OrderStatus) {
    const classes = {
      COMPLETED: 'success',
      PENDING: 'info',
      CANCELED: 'warning',
      ERROR: 'danger',
      PROCESSED: 'success',
      PROCESSING: 'warning',
      TO_PROCESS: 'primary',
      REGULARIZATION: 'reg',
      PREAUTHORIZED: 'success',
      WAITING_FOR_PAYMENT: 'waiting_payment',
      TO_REFUND: 'primary',
      REFUNDED: 'refunded',
      PAYMENT_ISSUE: 'danger',
      NULL: 'default',
    };

    return classes[status];
  }

  public supportTitle(item) {
    const support = this.determineSupport(item);

    const titles = {
      DEMATERIALIZED: this.translate.instant('pages.order_details.support_demat'),
      PHYSICAL: this.translate.instant('pages.order_details.support_physical'),
      MEDIA: this.translate.instant('pages.order_details.support_media'),
      COLLECT: this.translate.instant('pages.order_details.support_collect'),
      SHIPPING: this.translate.instant('pages.order_details.support_shipping'),
    };

    return titles[support];
  }

  public supportIcon(item) {
    const support = this.determineSupport(item);

    const icons = {
      DEMATERIALIZED: heroicons.outline.deviceMobile,
      PHYSICAL: heroicons.outline.creditCard,
      MEDIA: heroicons.outline.cash,
      COLLECT: heroicons.outline.cursorClick,
      SHIPPING: heroicons.outline.home,
    };

    try {
      return icons[support];
    } catch {
      return icons['DEMATERIALIZED'];
    }
  }

  public postComment = async (comment: CommentFormValues) => {
    const request = await firstValueFrom(
      this.orderService.postOrderComment(this.id, comment.message)
    );

    if (request.success) {
      this.comments = await firstValueFrom(
        this.orderService.getOrderComments(this.id, this.version)
      );
    }

    return request;
  };

  public editComment = async (comment: CommentFormValues) => {
    const request = await firstValueFrom(
      this.orderService.editOrderComment(+comment.id, comment.message)
    );

    if (request.success) {
      this.comments = await firstValueFrom(
        this.orderService.getOrderComments(this.id, this.version)
      );
    }

    return request;
  };

  public deleteComment = async (id: number) => {
    const request = await firstValueFrom(this.orderService.deleteOrderComment(id));

    if (request.success) {
      this.comments = await firstValueFrom(
        this.orderService.getOrderComments(this.id, this.version)
      );
    }

    return request;
  };

  /**
   * Get the order item support for which it was bought.
   * If the support isn't set (newer feature) we fallback
   * on the old value which is `dematerialized` 1 or 0
   */
  public determineSupport(item: Item) {
    if (item.support) return item.support;

    return item.dematerialized === '1' ? 'DEMATERIALIZED' : 'PHYSICAL';
  }

  public getDiscountVatPrice(item: Item): number {
    if (item.discounted_qty > 0) {
      const discountList = this.getDiscountItemList(item.id);

      if (discountList.length) {
        let totalPrice = 0;
        let promoQty = 0;

        // For each discount we calculate the price of the product and his quantity
        for (const discount of discountList) {
          const { promo, qty } = this.extractDiscountValue(discount.value);

          // adds the price with the centimes promotional quantity
          if (discount.type === OrderDiscountType.ARTICLE_AMOUNT) {
            // promo is in centimes and price_w_vat in euros
            totalPrice += (item.price_w_vat - promo / 100) * qty;
          }

          // adds the price with the ratio promotional quantity
          if (discount.type === OrderDiscountType.ARTICLE_RATIO) {
            totalPrice += item.price_w_vat * (1 - promo) * qty;
          }

          promoQty += qty;
        }

        // adds the price with the rest of the quantity
        totalPrice += item.price_w_vat * (item.qty - promoQty);

        return totalPrice;
      }
    }

    // Default return without discount
    return item.price_w_vat * item.qty;
  }

  public getDiscountSourceItem(itemId: string): string {
    return this.getDiscountItemList(itemId)
      .map(({ sourceCode }) => sourceCode)
      .join(', ');
  }

  private getDiscountItemList(itemId: string): OrderDiscount[] {
    const regex = new RegExp(`@${itemId}`);
    return this.order.discounts.filter(({ value }) => regex.test(value));
  }

  private extractDiscountValue(value: string): { promo: number; id: number; qty: number } {
    // The value is construct like this "Price@ItemId×Qty"
    // Warning × is a special character this is normal
    const [promo, id, qty] = value.split(/[@×]/);

    return {
      promo: parseFloat(promo),
      id: parseInt(id),
      qty: parseInt(qty),
    };
  }

  public async openCheckoutModalConfirmation() {
    try {
      const choice = await this.modalService.open(
        this.translate.instant('pages.order_details.checkout_modal_title'),
        '',
        [
          {
            label: this.translate.instant('pages.order_details.checkout_modal_cancel'),
            value: 'CANCEL',
          },
          {
            label: this.translate.instant('pages.order_details.checkout_modal_confirm'),
            value: 'CONFIRM',
          },
        ]
      );

      // If the user doesn't confirm the action we don't do anything
      if (choice !== 'CONFIRM') return;

      const success = await this.orderService.checkoutOrder(this.order.id);

      const message = match(success)
        .with(true, () => ({
          title: this.translate.instant('pages.order_details.checkout_success_message'),
          type: NotificationType.Success,
        }))
        .otherwise(() => ({
          title: this.translate.instant('pages.order_details.checkout_error_message'),
          type: NotificationType.Error,
        }));

      if (success) {
        // Even if the order is already in the status Completed, we might as well set it to Completed
        await this.updateStatus(OrderStatus.completed);
        this.selectedStatus = OrderStatus.completed;
      }

      await this.fetchOrderDetails();

      this.notification.create(message.title, '', message.type);
    } catch (error) {
      // TODO: Find a way to dispatch the error within Sentry
      console.error(error);
      this.notification.error(
        this.translate.instant('pages.order_details.checkout_error_message'),
        ''
      );
    }
  }

  public getProductTrip(productId: number): Trip | null {
    const article = this.articles.find(({ product }) => +product.id === +productId);

    if (article && article.trip) {
      return article.trip;
    }

    return null;
  }

  public getProductBooking(productId: number): ArticleBooking | null {
    const article = this.articles.find(({ product }) => +product.id === +productId);

    if (article && article.reservation) {
      return article.reservation;
    }

    return null;
  }
}
