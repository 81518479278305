import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FeatureGuard } from '../guards/feature.guard';

@Directive({
  selector: '[hasFeature]',
})
export class HasFeatureDirective implements OnInit {
  @Input('hasFeature') feature: string;

  constructor(private featureGuard: FeatureGuard, private element: ElementRef) {}

  ngOnInit() {
    if (this.feature) {
      const allowed = this.featureGuard.hasFeature(this.feature);

      if (!allowed) {
        this.element.nativeElement.remove();
      }
    }
  }
}
