import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { CustomersService } from '@app/modules/customers/services/customers.service';
import { TableServerComponent } from '@app/modules/shared/components/table-server/table-server.component';
import { ColumnConfig } from '@app/modules/shared/models/server-pagination';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { FilterService } from '@app/modules/shared/services/filter.service';
import { QueryParamsService } from '@app/modules/shared/services/queryParams.service';
import { ServerPaginationService } from '@app/modules/shared/services/server-pagination.service';
import { ProductItem } from '@app/modules/shop/models/product-item';
import { ShopService } from '@app/modules/shop/services/shop.service';
import { heroicons } from '@assets/icons/heroicons';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { Order, OrderFilters } from '../models/order';
import { OrdersService } from '../services/orders.service';
import { Article } from '@app/modules/orders/models/article';

@Component({
  selector: 'tu-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [OrdersService],
})
export class OrdersComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private customersService: CustomersService,
    private shopService: ShopService,
    private filterService: FilterService,
    private translate: TranslateService,
    private queryParamsService: QueryParamsService,
    public serverPaginationService: ServerPaginationService
  ) {}

  @ViewChild('table', { static: true }) table: TableServerComponent;

  get shouldShowOrderSearch(): boolean {
    return window['__CONFIG__']?.features?.orderSearch ?? true;
  }

  public filters: OrderFilters = {};

  public filterOptionsForm = this.fb.group({
    order_status: [null],
    order_process_status: [null],
    shipping_mode: [null],
    order_installment: [null],
    order_price: [null],
    payment_method: [null],
    virtual_provider: [null],
    origin_type: [null],
    product_id: [null],
    attribute: [null],
    attribute_search: [null],
  });

  public productItems: ProductItem[] = [];
  public filteredProductItems: ProductItem[] = [];

  public productFilterControl = new UntypedFormControl();

  public attributes;

  get selectedAttribute() {
    const hasAttributes = Boolean(this.attributes);
    const hasSelectedAttribute = Boolean(this.filterOptionsForm.value.attribute);

    if (!hasAttributes || !hasSelectedAttribute) return false;

    const attribute = this.attributes.find(
      (attribute) => +attribute.id === +this.filterOptionsForm.value.attribute
    );

    return {
      ...attribute,
      values: attribute?.values?.sort((a: string, b: string) => a.localeCompare(b)) ?? undefined,
    };
  }

  public computeLink(order: Order) {
    return `/orders/${order.id}`;
  }

  public get columnsConfig(): ColumnConfig<Order, Order | Article>[] {
    return [
      {
        key: 'articles',
        label: this.translate.instant('pages.order.detail'),
        type: 'details',
        config: {
          table: [
            {
              key: 'productTitle',
              label: this.translate.instant('pages.order.article_title'),
              type: 'link',
              isSortable: false,
              config: {
                href: (_, row) => {
                  if ('productId' in row) {
                    return `/shop/products/edit/${row.productId}`;
                  }
                },
              },
            },
            {
              key: 'quantity',
              label: this.translate.instant('pages.order.article_quantity'),
              type: 'text',
              isSortable: false,
            },
            {
              key: 'productUnitPrice',
              label: this.translate.instant('pages.order.article_unit_price'),
              type: 'price',
              isSortable: false,
              config: {
                currency: (order) => {
                  if ('network_id' in order) {
                    const network = this.authService.networks.find(
                      (network) => +network.id === +order.network_id
                    );
                    return network?.currency ?? 'EUR';
                  }
                },
                locale: () => this.translate.currentLang,
              },
            },
            {
              key: '',
              label: this.translate.instant('pages.order.article_total_price'),
              type: 'sumQuantityPrice',
              isSortable: false,
              config: {
                quantityKey: 'quantity',
                priceKey: 'productUnitPrice',
                currency: (order) => {
                  if ('network_id' in order) {
                    const network = this.authService.networks.find(
                      (network) => +network.id === +order.network_id
                    );
                    return network?.currency ?? 'EUR';
                  }
                },
                locale: () => this.translate.currentLang,
              },
            },
          ],
        },
      },
      {
        key: 'order_identifier',
        label: this.translate.instant('pages.order.sale_id'),
        type: 'text',
        isSortable: true,
      },
      {
        key: 'date',
        label: this.translate.instant('pages.order.date'),
        type: 'date',
      },
      {
        key: 'user.fullname',
        label: this.translate.instant('pages.order.client'),
        type: 'link',
        isSortable: true,
        config: {
          href: (_, row) => {
            return `/customers/${row.user.customer_id}`;
          },
        },
      },
      {
        key: 'user.email',
        label: this.translate.instant('pages.order.mail_address'),
        type: 'link',
        config: {
          href: (_, row) => `mailto:${row.user.email}`,
        },
      },
      {
        key: 'total_vat',
        label: this.translate.instant('pages.order.total'),
        type: 'price',
        config: {
          currency: (order) => {
            const network = this.authService.networks.find(
              (network) => +network.id === +order.network_id
            );
            return network?.currency ?? 'EUR';
          },
          locale: () => this.translate.currentLang,
        },
        isSortable: true,
      },
      {
        key: 'installments',
        label: this.translate.instant('pages.order.payment'),
        type: 'text',
        modifier: (installements) => {
          return `${installements} ${this.translate.instant('pages.order.times')}`;
        },
        isSortable: true,
      },
      {
        key: 'payment_method',
        label: this.translate.instant('pages.order.payment_method'),
        type: 'badge',
        isSortable: true,
        config: {
          CARD: { type: 'information', label: this.translate.instant('pages.order.card') },
          TRANSFER: { type: 'warning', label: this.translate.instant('pages.order.transfer') },
          DEPOSIT: { type: 'success', label: this.translate.instant('pages.order.deposit') },
          CASH: { type: 'success', label: this.translate.instant('pages.order.cash') },
        },
      },
      {
        key: 'wasCustomerAuthAsGuest',
        label: this.translate.instant('pages.order.guest_mode'),
        type: 'badge',
        modifier(wasCustomerAuthAsGuest: boolean) {
          return wasCustomerAuthAsGuest ? 'YES' : 'NO';
        },
        config: {
          YES: { type: 'information', label: this.translate.instant('otherslabels.yes') },
          NO: { type: 'information', label: this.translate.instant('otherslabels.no') },
        },
      },
      {
        key: 'item_subscription_count',
        label: this.translate.instant('pages.order.tacit'),
        type: 'badge',
        isSortable: true,
        modifier(count: string) {
          return Number.parseInt(count, 10) ? 'YES' : 'NO';
        },
        config: {
          YES: { type: 'success', label: this.translate.instant('otherslabels.yes') },
          NO: { type: 'danger', label: this.translate.instant('otherslabels.no') },
        },
      },
      {
        key: 'status',
        label: this.translate.instant('pages.order.status'),
        type: 'badge',
        isSortable: true,
        config: {
          CANCELED: { type: 'warning', label: this.translate.instant('pages.order.cancelled') },
          ERROR: { type: 'danger', label: this.translate.instant('pages.order.refused') },
          COMPLETED: { type: 'success', label: this.translate.instant('pages.order.ok') },
          PENDING: { type: 'information', label: this.translate.instant('pages.order.unfinished') },
          REGULARIZATION: {
            type: 'warning',
            label: this.translate.instant('pages.order.regularization'),
          },
          PROCESSING: { type: 'warning', label: this.translate.instant('pages.order.processing') },
          PREAUTHORIZED: {
            type: 'success',
            label: this.translate.instant('pages.order.preauthorized'),
          },
          WAITING_FOR_PAYMENT: {
            type: 'warning',
            label: this.translate.instant('pages.order.waiting_for_payment'),
          },
          TO_REFUND: {
            type: 'information',
            label: this.translate.instant('pages.order.to_refund'),
          },
          REFUNDED: {
            type: 'success',
            label: this.translate.instant('pages.order.refunded'),
          },
          PAYMENT_ISSUE: {
            type: 'danger',
            label: this.translate.instant('pages.order.default_payment'),
          },
        },
      },
      {
        key: 'process_status',
        label: this.translate.instant('pages.order.process_status'),
        type: 'badge',
        isSortable: true,
        modifier(status, order) {
          return order.status === 'COMPLETED' ||
            order.status === 'PREAUTHORIZED' ||
            order.status === 'WAITING_FOR_PAYMENT'
            ? status
            : 'NULL';
        },
        config: {
          TO_PROCESS: {
            type: 'information',
            label: this.translate.instant('pages.order.to_process'),
          },
          PROCESSING: { type: 'warning', label: this.translate.instant('pages.order.processing') },
          PROCESSED: {
            type: 'success',
            label: this.translate.instant('pages.order.processed'),
          },
          AUTOPROCESSED: {
            type: 'information',
            label: this.translate.instant('pages.order.autoprocessed'),
          },
          NULL: { type: 'hidden', label: '' },
        },
      },
      {
        key: 'supports',
        label: this.translate.instant('pages.order.delivery'),
        type: 'icon',
        config: {
          label: (supports: string[]) => {
            const map = {
              PHYSICAL: this.translate.instant('pages.order.support_physical'),
              DEMATERIALIZED: this.translate.instant('pages.order.support_demat'),
              COLLECT: this.translate.instant('pages.order.support_collect'),
              MEDIA: this.translate.instant('pages.order.support_media'),
              SHIPPING: this.translate.instant('pages.order.support_shipping'),
            };

            const labels = supports
              .sort()
              .map((support) => map[support])
              .join(', ');

            return supports.length > 1
              ? `${this.translate.instant('pages.order.support_mix')} : ${labels}`
              : `${this.translate.instant('pages.order.delivery')} : ${labels}`;
          },
          svgPaths: (supports: string[]) => {
            const map = {
              PHYSICAL: heroicons.outline.creditCard,
              DEMATERIALIZED: heroicons.outline.deviceMobile,
              COLLECT: heroicons.outline.cursorClick,
              MEDIA: heroicons.outline.cash,
              SHIPPING: heroicons.outline.home,
            };

            return supports.sort().map((support) => {
              try {
                return map[support];
              } catch {
                return map['DEMATERIALIZED'];
              }
            });
          },
        },
        isSortable: true,
      },
      {
        key: 'user.customer_id',
        label: this.translate.instant('pages.order.user_id'),
        type: 'text',
        isDisplayed: () => false,
        isSortable: true,
      },
      {
        key: 'network_id',
        label: this.translate.instant('pages.order.network'),
        type: 'text',
        modifier: (id) => this.authService.networks.find((n) => +n.id === +id).name,
        isSortable: true,
        isExportable: this.authService.networks.length > 1,
        isDisplayed: () => this.authService.networks.length > 1,
      },
      {
        key: 'origin_type',
        label: this.translate.instant('pages.order.origin'),
        type: 'text',
        modifier: (origin) => {
          return origin || '-';
        },
        isSortable: true,
      },
      {
        key: 'voucher',
        label: this.translate.instant('pages.order.voucher'),
        type: 'text',
        isSortable: false,
      },
      {
        key: '',
        label: this.translate.instant('pages.order.promotional_codes'),
        type: 'text',
        modifier: (_, order: Order) => {
          if (order.discounts) {
            return order.discounts.length;
          }

          return 0;
        },
        isSortable: false,
      },
      {
        key: 'virtual_provider',
        label: this.translate.instant('otherslabels.col_platform'),
        type: 'image',
        modifier: (_, order) => {
          return this.determineVirtualProvider(order);
        },
        config: {
          alt: (value: string) => value,
          src(value: string) {
            if (value?.toLowerCase().includes('instantsystem')) {
              return '/assets/img/providers/instant_system.png';
            }

            switch (value) {
              case 'tixipass':
                return '/assets/img/logo_tixipass.png';
              case 'sncf':
                return '/assets/img/providers/sncf.png';
              case 'smt_nfc':
                return '/assets/img/providers/smt_nfc.png';
              case 'modalis':
                return '/assets/img/providers/modalis.png';
              case 'otipass':
                return '/assets/img/providers/otipass.png';
              default:
                return '';
            }
          },
        },
        isSortable: true,
        isDisplayed: () => this.authService.networks.some((network) => network.universal),
      },
      {
        key: 'payment_token',
        label: this.translate.instant('pages.order.media_transaction'),
        type: 'text',
      },
    ];
  }

  public computeFilters() {
    const globalFilters = this.filterService.filters;

    const {
      shipping_mode,
      order_status,
      order_process_status,
      order_installment,
      order_price,
      virtual_provider,
      payment_method,
      origin_type,
      product_id,
      attribute,
      attribute_search,
    } = this.filterOptionsForm.value;

    //TODO set network as string in whole app
    const filters: Record<string, string> = {
      network_id: globalFilters.network,
      from: globalFilters.from,
      to: globalFilters.to,
      query: globalFilters.query,
      status: order_status,
      processingStatus: order_process_status,
      supports: shipping_mode,
      installments: order_installment,
      provider: virtual_provider,
      paymentMethod: payment_method,
      originType: origin_type,
      productId: product_id,
      attribute: attribute,
      attributeSearch: attribute_search,
    };

    // FIXME: This help prevent boolean casting on the server side
    if (['string', 'number'].includes(typeof order_price)) {
      filters.total = +order_price === 0 ? '000' : String(+order_price * 100);
    }

    this.filters = filters;

    this.queryParamsService.localFilterParams = this.filterOptionsForm.value;
  }

  ngOnInit() {
    if (!this.shouldShowOrderSearch) {
      this.filterService.reset();
    }

    const params = this.queryParamsService.localFilterParams;

    const formParams: Record<string, string> = {};

    for (const property of Object.keys(this.filterOptionsForm.value)) {
      formParams[property] = params[property] || null;
    }

    this.filterOptionsForm.setValue(formParams);

    this.computeFilters();

    let unsubscribeToAttribueChanges: Subscription;

    this.customersService.getAttributes().then((attributes) => {
      // We only want to filter on disability_benefits or social_fund (at least, for now)
      const attributeFiltersKeys = ['disability_benefits', 'social_fund'];

      this.attributes = attributes.filter((attribute) =>
        attributeFiltersKeys.includes(attribute.key)
      );

      if (unsubscribeToAttribueChanges?.unsubscribe) {
        unsubscribeToAttribueChanges.unsubscribe();
      }

      unsubscribeToAttribueChanges =
        this.filterOptionsForm.controls.attribute.valueChanges.subscribe((value) => {
          const attribute = this.attributes.find((attribute) => attribute.id === value);
          this.filterOptionsForm.controls.attribute_search.setValue(attribute?.values?.[0] || null);
        });
    });

    this.shopService.getProductsItem().subscribe((productItems) => {
      if (!Array.isArray(productItems)) return;

      this.productItems = productItems;
    });

    this.productFilterControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((product) => {
        if (product) {
          // Since some products have the same name, we also need to use product ID as autocomplete value
          // '|' is used as a separator between the ID and name when a product is actually selected
          // The product filter will work as long as no product contains a pipe in its name
          const productData = product.split(' | ');

          const isProductSelected = productData.length === 2;

          const filterValue = !isProductSelected
            ? productData[0].toLowerCase()
            : productData[1].toLowerCase();

          this.filteredProductItems = this.productItems.filter((productItem) => {
            return (
              productItem.name.toLowerCase().includes(filterValue) ||
              `${productItem.id}`.includes(filterValue)
            );
          });

          const productItemId = isProductSelected ? productData[0] : null;
          this.filterOptionsForm.controls.product_id.setValue(productItemId);
        } else {
          this.filteredProductItems = this.productItems;
          this.filterOptionsForm.controls.product_id.setValue(null);
        }
      });
  }

  // Export API
  get exportInformation() {
    const hasCheckedRows = this.table.selectedRows.length;
    const checkedRowsIds = hasCheckedRows ? this.table.selectedRows.map((row) => row.id) : [];

    const ordersFilters = this.filterOptionsForm.value;

    const filters = {};

    // Add filters only if needed
    if (ordersFilters.order_status) {
      filters['status'] = ordersFilters.order_status;
    }

    if (ordersFilters.order_process_status) {
      filters['processingStatus'] = ordersFilters.order_process_status;
    }

    if (ordersFilters.shipping_mode) {
      filters['supports'] = ordersFilters.shipping_mode;
    }

    if (ordersFilters.order_installment !== null) {
      filters['installments'] = ordersFilters.order_installment;
    }

    if (ordersFilters.order_price !== null) {
      filters['total'] = ordersFilters.order_price * 100;
    }

    if (ordersFilters.virtual_provider) {
      filters['provider'] = ordersFilters.virtual_provider;
    }

    if (ordersFilters.payment_method) {
      filters['paymentMethod'] = ordersFilters.payment_method;
    }

    if (ordersFilters.product_id) {
      filters['productId'] = ordersFilters.product_id;
    }

    if (ordersFilters.origin_type) {
      filters['originType'] = ordersFilters.origin_type;
    }

    const isAttributeFilterSelected = ordersFilters.attribute && ordersFilters.attribute_search;

    if (isAttributeFilterSelected) {
      filters['attributeId'] = ordersFilters.attribute.toString();
      filters['attributeValue'] = ordersFilters.attribute_search.toString();
    }

    return {
      exportId: 'cms-orders-v2',
      checkedRowsIds: checkedRowsIds,
      hasCheckedAll: this.table.allSelected,
      filters: filters,
    };
  }

  private determineVirtualProvider(order: Order): string {
    return Boolean(order.universal)
      ? 'tixipass'
      : Boolean(order.client)
      ? order.client.slug
      : order.issuer;
  }
}
