import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TableElementType, TagType } from '@app/modules/shared/components/table/table.component';
import { PermissionGuard } from '@app/modules/shared/guards/permission.guard';
import { CommentFormValues } from '@app/modules/shared/models/comment';
import { Network } from '@app/modules/shared/models/network';
import { Ticket, TicketPurpose, TicketPurposeLabel } from '@app/modules/shared/models/ticket';
import { Voucher } from '@app/modules/shared/models/voucher';
import { KeyPipe } from '@app/modules/shared/pipes/key.pipe';
import { SearchPipe } from '@app/modules/shared/pipes/search.pipe';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { DeleteDocumentModalComponent } from '@app/modules/submissions/components/submission-detail/delete-document-modal/delete-document-modal.component';
import { SubmissionService } from '@app/modules/submissions/services/submission.service';
import { SubscriptionDetails } from '@app/modules/subscriptions/models/subscription.interface';
import { heroicons } from '@assets/icons/heroicons';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { DateFormatPipe } from 'ngx-moment';
import { firstValueFrom, Observable, startWith } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UpdateDocumentModalComponent } from '../../../shared/components/update-document-modal/update-document-modal.component';
import {
  Attribute,
  Contract,
  CustomerOrderStatus,
  CustomerValidation,
  CustomerWalletEvent,
  CustomerWalletEventType,
  Document,
  Session,
  Transfer,
} from '../../models/customer';
import {
  $$Comment,
  $$Customer,
  $$GlobalAttribute,
  $$Profile,
  $$Ticket,
  CustomersService,
} from '../../services/customers.service';
import { $$Item, ProductsService } from '../../services/products.service';
import { TicketService } from '../../services/ticket.service';
import { AnonymizationModalComponent } from './anonymization-modal/anonymization-modal.component';
import {
  AttributesModalComponent,
  DialogData,
} from './attributes-modal/attributes-modal.component';
import { ImageUpdateModalComponent } from './avatar-update-modal/avatar-update-modal.component';
import { InformationModalComponent } from './information-modal/information-modal.component';
import { CustomerLostPhoneModalComponent } from './lost-phone-modal/customer-lost-phone-modal.component';
import { NetworkDissociationModalComponent } from './network-dissociation-modal/network-dissociation-modal.component';
import { TicketGenerationModalComponent } from './ticket-generation-modal/ticket-generation-modal.component';
import { compareAsc, differenceInMinutes } from 'date-fns';
import { Blueprint } from '@app/modules/shop/models/blueprint/blueprint';
import { FusionModalComponent } from './fusion-modal/fusion-modal.component';
import { SubscriptionsService } from '@app/modules/subscriptions/services/subscriptions.service';
import { PaperworkService } from '../../services/paperwork.service';
import { UsersService } from '@app/modules/users/services/users.service';
import { User } from '@app/modules/users/models/user';
import { match } from 'ts-pattern';
import { ColumnConfig } from '@app/modules/shared/models/server-pagination';
import { ServerPaginationService } from '@app/modules/shared/services/server-pagination.service';
import { Belonging, BookingFilters } from '../../models/booking';
import { DatePipe } from '@angular/common';
import { ArticleBooking } from '@app/modules/orders/models/order';
import {
  DeleteTicketModalComponent,
  DeleteTicketModalResponse,
} from './delete-ticket-modal/delete-ticket-modal.component';
import { ERROR_FIREBASE_RESET_PASSWORD } from '../../constants/resetPassword';
import { ShopService } from '../../services/shop.service';
import { DocumentUploadModalComponent } from './document-upload-modal/document-upload-modal.component';

const CONTRACT_STATUS = {
  CANCELED: 'pages.customer_details.status_canceled',
  EXPIRED: 'pages.customer_details.status_expired',
  REMAINING: 'pages.customer_details.status_remaining',
  PENDING: 'pages.customer_details.status_pending',
  EMPTY: 'pages.customer_details.status_empty',
  ACTIVE: 'pages.customer_details.status_active',
} as const;

@Component({
  selector: 'tu-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
  providers: [CustomersService, TicketService, ProductsService, SearchPipe, KeyPipe, DatePipe],
})
export class CustomerDetailComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public translate: TranslateService,
    public modal: MatDialog,
    private customersService: CustomersService,
    private ticketService: TicketService,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private permissionGuard: PermissionGuard,
    private notification: NotificationsService,
    private router: Router,
    private dateFormat: DateFormatPipe,
    private submissionService: SubmissionService,
    private searchPipe: SearchPipe,
    private keyPipe: KeyPipe,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public lostPhoneModal: MatDialog,
    private paperworkService: PaperworkService,
    private subscriptionService: SubscriptionsService,
    private userService: UsersService,
    public serverPaginationService: ServerPaginationService,
    private datePipe: DatePipe,
    private shopService: ShopService
  ) { }

  private facebookRegex = /https:\/\/scontent\.xx\.fbcdn\.net\/v\/t1\.0-1\/[^\/]*\/\d*_(\d*)_.*/g;

  public customerId: string | null = null;

  public $$customer: $$Customer;
  public $$loadingCustomer = true;

  public $$profiles: $$Profile[] = [];
  public $$loadingProfiles = true;

  public isTicketV1Enabled: boolean = false;

  public get disabledAddTicket() {
    const activeTab = this.activeTicketsTab;

    if (activeTab === 'v1') {
      return !this.isTicketV1Enabled;
    }

    if (activeTab === 'v2') {
      return !this.isTicketV2Enabled;
    }

    return true;
  }

  public $$wallet: $$Ticket[] = [];
  public $$errorWallet: boolean = false;
  public $$filteredWallet: $$Ticket[] = [];
  public $$loadingWallet = true;
  public $$selectedTicketRows = [];

  public $$contracts: Contract[] = [];
  public $$filteredContracts: Contract[] = [];
  public $$loadingContracts: boolean = true;
  public $$errorContracts: boolean = false;

  public bookingFilters: BookingFilters = {};

  public $$validations: CustomerValidation[] = [];
  public $$loadingValidations = false;
  public $$walletEvents: CustomerWalletEvent[] = [];
  public $$loadingwalletEvents = false;
  public get customerValidationsView() {
    if (this.$$validations.length && this.$$walletEvents.length) return 'TABS';
    if (this.$$validations.length) return 'V1';
    if (this.$$walletEvents.length) return 'V2';
    return 'NONE';
  }

  private _cmsUsers: User[] = [];
  public get hasV1Tickets() {
    return this.$$filteredWallet?.length > 0;
  }
  public get hasV2Tickets() {
    return Boolean(this.$$filteredContracts?.length);
  }
  public get isTicketV2Enabled() {
    return (
      this.$$customerNetworks?.some((customerNetwork) => customerNetwork.blueprints.length > 0) ||
      false
    );
  }

  public get isBookingEnabled() {
    return this.$$networks.some((network) => network.hasReservation) || false;
  }

  private get activeTicketsTab() {
    if (this.ticketV1Tab?.active) {
      return 'v1';
    }

    if (this.ticketV2Tab?.active) {
      return 'v2';
    }

    if (this.bookingTab?.active) {
      return 'booking';
    }
  }

  public get isFusionDisabled() {
    const isDisabled =
      this.$$customer.active === '0' || this.$$customer.anonymized_at || !this.isCustomerParent;
    return isDisabled ? true : null;
  }

  public get emailIsValid(): boolean {
    if (this.$$customer.emailBouncedAt) {
      const now = new Date();
      const emailBouncedAt = new Date(this.$$customer.emailBouncedAt);

      if (this.$$customer.emailBouncedUntil) {
        const emailBouncedUntil = new Date(this.$$customer.emailBouncedUntil);

        return emailBouncedUntil < now;
      }

      return emailBouncedAt > now;
    }

    return true;
  }

  public get canResetPassword(): boolean {
    return Boolean(this.$$customer.firebaseUid);
  }

  public $$medias: any[] = [];
  public $$loadingMedias = true;

  public $$subscriptions: SubscriptionDetails[] = [];
  public $$loadingSubscriptions = true;

  public $$networks: Network[] = [];
  public $$isAddingNetwork = false;
  public $$newNetworkId = '';
  public $$newNetWorkLoading = false;
  public $$customerNetworks: { network: Network; blueprints: Blueprint[] }[];

  public $$documents: Document[] = [];
  public $$deletedDocuments: Document[] = [];
  public $$loadingDocuments = true;

  public $$submissions: any[] = [];
  public $$loadingSubmissions = true;

  public $$vouchers: Voucher[] = [];
  public $$loadingVouchers = true;

  public $$allAttributes: $$GlobalAttribute[] = [];
  public $$loadingAllAttributes = true;

  public $$attributes: Attribute[] = [];
  public $$loadingAttributes = true;

  public $$comments: $$Comment[] = [];
  public $$loadingComments = true;
  public $$selectedCommentNetwork = '';

  public $$products: $$Item[] = [];
  public $$loadingProducts = true;

  public sessions: Session[] = [];

  public isSuperAdmin = false;

  public heroicons = heroicons;

  public filterForm = this.fb.group({
    status: [null],
    customerQuery: [null],
    customerNetworkName: [null],
    customerNetwork: [null],
    origin: [null],
  });

  public filterOrderHistory = this.fb.group({
    status: [null],
  });

  // Used for autocomplete
  public filteredNetworksName: Observable<string[]> =
    this.filterForm.controls.customerNetworkName.valueChanges.pipe(
      startWith(null),
      map((networkName: string | null) => {
        const networkNames = this.auth.networks.map((network) => network.name);
        if (!networkName) return networkNames;

        const network = this.auth.networks.find((network) => network.name === networkName);

        if (network) {
          this.filterForm.controls.customerNetwork.setValue(+network.id);
        }

        return networkNames.filter((network) => {
          return network.toLowerCase().includes(networkName.toLowerCase());
        });
      })
    );

  public isActionOpen = false;

  public notificationOptions = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: false,
    clickToClose: false,
  };

  //TRANSFER HISTORY
  public customer_history: Transfer[] = [];

  @ViewChild('ticketV1Tab') ticketV1Tab;
  @ViewChild('ticketV2Tab') ticketV2Tab;
  @ViewChild('bookingTab') bookingTab;

  private styles = {
    canceled: {
      text: 'color:gray; text-decoration:line-through; background-color:rgb(232, 232, 232);',
      icon: 'color:transparent; pointer-events: none;background-color:rgb(232, 232, 232);',
      tag: 'background-color: rgb(232, 232, 232);',
    },
  };

  public statusType = {
    PENDING: {
      count: 0,
      label: this.translate.instant('pages.customer_details.validated_titles'),
    },
    ACTIVE: {
      count: 0,
      label: this.translate.instant('pages.customer_details.to_be_validated_titles'),
    },
    REMAINING: {
      count: 0,
      label: this.translate.instant('pages.customer_details.remaining_titles'),
    },
    EXPIRED: {
      count: 0,
      label: this.translate.instant('pages.customer_details.expired_titles'),
    },
    TRANSFER_OUT: {
      count: 0,
      label: this.translate.instant('pages.customer_details.transfered_out_titles'),
    },
    TRANSFER_IN: {
      count: 0,
      label: this.translate.instant('pages.customer_details.transfered_in_titles'),
    },
  };

  public walletTableConfig = [
    {
      key: 'ticket_id',
      label: this.translate.instant('pages.customer_details.title_id'),
      type: TableElementType.Link,
      modifier: (tid: string, ticket: Ticket) => {
        return {
          link: ['/ticket', ticket.id],
          label: tid,
        };
      },
      style: this.canceledStyle.bind(this, this.styles.canceled.text),
    },
    {
      key: 'created_at',
      label: this.translate.instant('pages.customer_details.created_at'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date ? this.dateFormat.transform(date, 'DD-MM-YYYY') : null;
      },
    },
    {
      key: 'label',
      label: this.translate.instant('pages.customer_details.title'),
      type: TableElementType.Text,
      style: this.canceledStyle.bind(this, this.styles.canceled.text),
    },
    {
      key: 'order_identifier',
      label: this.translate.instant('pages.customer_details.sale'),
      type: TableElementType.Link,
      exportable: true,
      style: this.canceledStyle.bind(this, this.styles.canceled.text),
      modifier: (oid: string, ticket: Ticket) => {
        return {
          link: this._walletOriginLink({ ticket }),
          label: this._walletOriginLabel({ ticket }),
        };
      },
    },
    {
      key: 'status',
      label: this.translate.instant('pages.customer_details.status'),
      type: TableElementType.Tag,
      config: {
        tags: {
          EXPIRED: {
            type: TagType.Danger,
            label: this.translate.instant('pages.customer_details.expired'),
          },
          PENDING: {
            type: TagType.Success,
            label: this.translate.instant('pages.customer_details.validated'),
          },
          ACTIVE: {
            type: TagType.Warning,
            label: this.translate.instant('pages.customer_details.to_be_validated'),
          },
          REMAINING: {
            type: TagType.Info,
            label: this.translate.instant('pages.customer_details.remaining'),
          },
          CANCELED: {
            type: TagType.Default,
            label: this.translate.instant('pages.customer_details.canceled'),
          },
          CANCELLED: {
            type: TagType.Default,
            label: this.translate.instant('pages.customer_details.canceled'),
          },
        },
      },
      style: this.canceledStyle.bind(this, this.styles.canceled.tag),
    },

    {
      key: 'dematerialized',
      label: this.translate.instant('pages.order.delivery'),
      type: TableElementType.Icon,
      config: {
        source: 'heroicons',
        labels: {
          '0': this.translate.instant('pages.customer_details.shipping_physical'),
          '1': this.translate.instant('pages.customer_details.shipping_demat'),
        },
        icons: {
          '0': heroicons.outline.creditCard,
          '1': heroicons.outline.deviceMobile,
        },
      },
    },
    {
      key: 'network_id',
      label: this.translate.instant('pages.submissions.network'),
      type: TableElementType.Text,
      modifier: (networkId: string) => {
        const network = this.auth.getNetwork(networkId);

        return network?.name ?? '-';
      },
      isSortable: true,
    },
    {
      key: 'generationReason',
      label: this.translate.instant('pages.customer_details.generationreason'),
      type: TableElementType.Tag,
      modifier: (generationReason: string | null) => {
        return generationReason || 'EMPTY';
      },
      config: {
        tags: {
          DEVICE_CHANGE: {
            type: TagType.Warning,
            label: this.translate.instant(TicketPurposeLabel['DEVICE_CHANGE']),
          },
          COMMERCIAL_GESTURE: {
            type: TagType.Warning,
            label: this.translate.instant(TicketPurposeLabel['COMMERCIAL_GESTURE']),
          },
          COMMERCIAL_OFFER: {
            type: TagType.Warning,
            label: this.translate.instant(TicketPurposeLabel['COMMERCIAL_OFFER']),
          },
          CUSTOMER_SUPPORT: {
            type: TagType.Warning,
            label: this.translate.instant(TicketPurposeLabel['CUSTOMER_SUPPORT']),
          },
          TEST: {
            type: TagType.Warning,
            label: this.translate.instant(TicketPurposeLabel['TEST']),
          },
          EMPTY: {
            type: TagType.Default,
            label: this.translate.instant('otherslabels.unkown'),
          },
        },
      },
      isSortable: false,
    },
    {
      key: 'generationComment',
      label: this.translate.instant('pages.customer_details.comment'),
      type: TableElementType.Text,
      style: this.canceledStyle.bind(this, this.styles.canceled.text),
    },
    {
      key: 'id',
      label: this.translate.instant('pages.customer_details.action'),
      type: TableElementType.Submenu,
      displayed: this.permissionGuard.isOnePermissionAllowed(['MANAGE_CUSTOMER_WALLET']),
      config: {
        icon: heroicons.outline.dotsVertical,
        actions: [
          {
            label: this.translate.instant('pages.customer_details.transfert'),
            fn: (ticket: $$Ticket) => {
              console.log(ticket);
              this.openTicketGenerationModal(ticket);
            },
            icon: heroicons.outline.documentDuplicate,
            active: this._isTicketNotCanceled,
          },
          {
            label: this.translate.instant('otherslabels.btn_delete'),
            fn: async (ticket: $$Ticket) => {
              this._desactivateTicket(ticket);
            },
            icon: heroicons.outline.trash,
            active: this._isTicketNotCanceled,
          },
          {
            label: this.translate.instant('pages.customer_details.reactivate'),
            fn: async (ticket: Ticket) => {
              if (
                !this.permissionGuard.isOnePermissionAllowed(['MANAGE_CUSTOMER_WALLET']) ||
                !['CANCELED', 'CANCELLED'].includes(ticket.status)
              )
                return;
              const ok = await this.ticketService.reactivateTicket(+ticket.id);
              if (ok) {
                this.updateWallet(this.$$customer.customer_id);
                this.notification.success(
                  this.translate.instant(`otherslabels.notif_reactivate_ok`)
                );
              } else {
                this.notification.error(this.translate.instant(`otherslabels.notif_reactivate_ko`));
              }
            },
            icon: heroicons.outline.refresh,
            active: this._isTicketCanceled,
          },
        ],
      },
    },
  ];

  public contractTableConfig = [
    {
      key: 'id',
      label: this.translate.instant('pages.customer_details_tickets_v2.contract'),
      type: TableElementType.Link,
      modifier: (id: string) => {
        return {
          link: ['/ticket', 'v2', id],
          label: id,
        };
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
      class: {
        row: 'tw-text-xs',
      },
    },
    {
      key: 'blueprintName',
      label: this.translate.instant('pages.customer_details_tickets_v2.ticket'),
      type: TableElementType.Text,
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
      class: {
        row: 'tw-text-sm',
      },
    },
    {
      key: '',
      label: this.translate.instant('pages.customer_details.sale'),
      type: TableElementType.Link,
      modifier: (empty: null, contract: Contract) => {
        return {
          link: this._walletOriginLink({ contract }),
          label: this._walletOriginLabel({ contract }),
        };
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
    },
    {
      key: 'generationReason',
      label: this.translate.instant('pages.customer_details.generationreason'),
      type: TableElementType.Tag,
      config: {
        tags: {
          DEVICE_CHANGE: {
            type: TagType.Warning,
            label: this.translate.instant(TicketPurposeLabel['DEVICE_CHANGE']),
          },
          COMMERCIAL_GESTURE: {
            type: TagType.Warning,
            label: this.translate.instant(TicketPurposeLabel['COMMERCIAL_GESTURE']),
          },
          COMMERCIAL_OFFER: {
            type: TagType.Warning,
            label: this.translate.instant(TicketPurposeLabel['COMMERCIAL_OFFER']),
          },
          CUSTOMER_SUPPORT: {
            type: TagType.Warning,
            label: this.translate.instant(TicketPurposeLabel['CUSTOMER_SUPPORT']),
          },
          TEST: {
            type: TagType.Warning,
            label: this.translate.instant(TicketPurposeLabel['TEST']),
          },
          EMPTY: {
            label: this.translate.instant('otherslabels.unkown'),
            type: TagType.Default,
          },
        },
      },
      modifier(purpose: TicketPurpose) {
        return purpose || 'EMPTY';
      },
      isSortable: false,
    },
    {
      key: 'generationComment',
      label: this.translate.instant('pages.customer_details.comment'),
      type: TableElementType.Text,
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
      class: {
        row: 'tw-text-sm',
      },
    },
    {
      key: 'createdAt',
      label: this.translate.instant('pages.customer_details_tickets_v2.date'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date ? this.dateFormat.transform(date, 'DD-MM-YYYY HH:mm') : null;
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
      class: {
        row: 'tw-text-sm',
      },
    },
    {
      key: 'state.contractStartDate',
      label: this.translate.instant('pages.customer_details_tickets_v2.starting_at'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date ? this.dateFormat.transform(date, 'DD-MM-YYYY HH:mm') : null;
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
      class: {
        row: 'tw-text-sm',
      },
    },
    {
      key: 'state.contractEndDate',
      label: this.translate.instant('pages.customer_details_tickets_v2.ending_at'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date ? this.dateFormat.transform(date, 'DD-MM-YYYY HH:mm') : null;
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
      class: {
        row: 'tw-text-sm',
      },
    },
    {
      key: 'firstEvent.occurredAt',
      label: this.translate.instant('pages.ticket_details_v2.first_validation'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date ? this.dateFormat.transform(date, 'DD-MM-YYYY HH:mm') : null;
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
      class: {
        row: 'tw-text-sm',
      },
    },
    {
      key: 'lastEvent.occurredAt',
      label: this.translate.instant('pages.ticket_details_v2.last_validation'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date ? this.dateFormat.transform(date, 'DD-MM-YYYY HH:mm') : null;
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
      class: {
        row: 'tw-text-sm',
      },
    },
    {
      key: 'blockedAt',
      label: this.translate.instant('pages.customer_details_tickets_v2.blocked_at'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date ? this.dateFormat.transform(date, 'DD-MM-YYYY HH:mm') : null;
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
      class: {
        row: 'tw-text-sm',
      },
    },
    {
      key: '',
      label: this.translate.instant('pages.customer_details.status'),
      type: TableElementType.Text,
      modifier: (empty: null, contract: Contract) => {
        const status_key = this._contractStatus(contract);
        return this.translate.instant(status_key);
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
    },
    {
      key: 'networkId',
      label: this.translate.instant('pages.submissions.network'),
      type: TableElementType.Text,
      modifier: (networkId: string) => {
        const network = this.auth.getNetwork(networkId);
        return network?.name ?? '-';
      },
      isSortable: true,
    },
    {
      key: 'state.validationRemainingPunches',
      label: this.translate.instant('pages.customer_details.tickets_number'),
      type: TableElementType.Text,
      modifier: (remainingPunch: number) => {
        return remainingPunch > 0 ? remainingPunch : null;
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
    },
    {
      key: 'transferredAt',
      label: this.translate.instant('pages.customer_details.contract_transferredat'),
      type: TableElementType.Tag,
      modifier: (transferredAt: string) => {
        return transferredAt ? 'YES' : 'NO';
      },
      config: {
        tags: {
          NO: { type: TagType.Default, label: this.translate.instant('otherslabels.no') },
          YES: { type: TagType.Primary, label: this.translate.instant('otherslabels.yes') },
        },
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
      class: {
        row: 'tw-text-center',
      },
    },
    {
      key: 'id',
      label: this.translate.instant('pages.customer_details.action'),
      type: TableElementType.Submenu,
      displayed: this.permissionGuard.isOnePermissionAllowed(['MANAGE_CUSTOMER_WALLET']),
      config: {
        icon: heroicons.outline.dotsVertical,
        actions: [
          {
            label: this.translate.instant('otherslabels.btn_delete'),
            fn: async (contract: Contract) => {
              this._desactivateContract(contract);
            },
            icon: heroicons.outline.trash,
            active: this._isContractActive,
          },
          {
            label: this.translate.instant('pages.customer_details.reactivate'),
            fn: async (contract: Contract) => {
              this._activateContract(contract);
            },
            icon: heroicons.outline.refresh,
            active: this._isContractCanceled,
          },
        ],
      },
      style: this.canceledContractStyle.bind(this, this.styles.canceled.text),
    },
  ];

  public get bookingTableConfig(): ColumnConfig[] {
    return [
      {
        key: 'id',
        label: this.translate.instant('pages.customer_details.booking_number'),
        type: 'text',
        isSortable: false,
      },
      {
        key: 'orderId',
        label: this.translate.instant('pages.customer_details.order'),
        type: 'link',
        config: {
          href: (_, belonging: Belonging) => {
            return `/orders/${belonging.orderId}`;
          },
        },
        isSortable: false,
      },
      {
        key: '',
        label: this.translate.instant('pages.customer_details.booking_details'),
        type: 'html',
        modifier: (_, belonging: Belonging) => {
          return this.getHtmlBelongingCell(belonging.reservation);
        },
        isSortable: false,
      },
      {
        key: 'status',
        label: this.translate.instant('pages.customer_details.status'),
        type: 'badge',
        config: {
          REMAINING: {
            type: 'information',
            label: this.translate.instant('pages.customer_details.remaining'),
          },
          IN_PROGRESS: {
            type: 'success',
            label: this.translate.instant('pages.customer_details.in_progress'),
          },
          EXPIRED: {
            type: 'default',
            label: this.translate.instant('pages.customer_details.expired'),
          },
        },
        modifier(_, belonging: Belonging) {
          const now = new Date();
          if (belonging.reservation) {
            const tripDepartureDate = new Date(belonging.reservation.tripDepartureDate);
            const tripArrivalDate = new Date(belonging.reservation.tripArrivalDate);

            if (tripDepartureDate > now) {
              return 'REMAINING';
            }

            if (now > tripDepartureDate && tripArrivalDate > now) {
              return 'IN_PROGRESS';
            }
          }
          return 'EXPIRED';
        },
        isSortable: false,
      },
      {
        key: 'networkId',
        label: this.translate.instant('pages.submissions.network'),
        type: 'text',
        modifier: (networkId: string) => {
          const network = this.auth.getNetwork(networkId);
          return network?.name ?? '-';
        },
        isSortable: false,
      },
    ];
  }

  public transfersHistoryTableConfig = [
    {
      key: 'direction',
      label: this.translate.instant('pages.customer_details.history_transfer_direction'),
      type: TableElementType.Tag,
      config: {
        tags: {
          IN: {
            type: TagType.Success,
            label: this.translate.instant('pages.customer_details.history_transfer_receive'),
          },
          OUT: {
            type: TagType.Warning,
            label: this.translate.instant('pages.customer_details.history_transfer_emit'),
          },
        },
      },
      class: {
        row: 'tw-text-base',
      },
    },
    {
      key: 'issuerCustomerLabel',
      label: this.translate.instant('pages.customer_details.history_emitter'),
      type: TableElementType.Link,
      modifier: (label: string, transfert: Transfer) => {
        return {
          link: transfert.direction === 'IN' ? ['/customers', transfert.issuerCustomerId] : '',
          label: label || transfert.issuerCustomerId,
        };
      },
    },
    {
      key: 'recipientCustomerLabel',
      label: this.translate.instant('pages.customer_details.history_recipient'),
      type: TableElementType.Link,
      modifier: (label: string, transfert: Transfer) => {
        return {
          link: transfert.direction === 'OUT' ? ['/customers', transfert.recipientCustomerId] : '',
          label: label || transfert.recipientCustomerId,
        };
      },
    },
    {
      key: 'createdAt',
      label: this.translate.instant('pages.customer_details.created_at'),
      type: TableElementType.Date,
    },
    {
      key: 'contractLabel',
      label: this.translate.instant('pages.customer_details.history_contractlabel'),
      type: TableElementType.Link,
      modifier: (contractLabel, row) => {
        return {
          link: ['/ticket/v2/', row.contractCode],
          label: contractLabel,
        };
      },
    },
  ];

  //Validations v1
  public validationsTableConfig = [
    {
      key: 'ticketId',
      label: this.translate.instant('pages.validation.title_id'),
      type: TableElementType.Link,
      modifier: (ticketId: number) => {
        return {
          link: ['/ticket', ticketId],
          label: ticketId,
        };
      },
    },
    {
      key: 'itemLabel',
      label: this.translate.instant('pages.validation.title'),
      type: TableElementType.Text,
    },
    {
      key: 'occurredAt',
      label: this.translate.instant('pages.validation.date_time'),
      type: TableElementType.Date,
      format: 'medium',
    },
    {
      key: 'isTransfer',
      label: this.translate.instant('pages.ticket_details.connection'),
      type: TableElementType.Tag,
      modifier: (isTransfer: boolean) => (isTransfer ? 'TRUE' : 'FALSE'),
      config: {
        tags: {
          TRUE: { type: TagType.Success, label: this.translate.instant('otherslabels.yes') },
          FALSE: { type: TagType.Danger, label: this.translate.instant('otherslabels.no') },
        },
      },
      class: {
        row: 'tw-text-center',
      },
    },
    {
      key: 'status',
      label: this.translate.instant('pages.validation.status'),
      type: TableElementType.Tag,
      modifier: (status) =>
        match(status)
          .when(
            (status) => ['EXPIRED', 'PENDING'].includes(status),
            () => status
          )
          .otherwise(() => null),
      config: {
        tags: {
          EXPIRED: {
            type: TagType.Danger,
            label: this.translate.instant('pages.validation.expired'),
          },
          PENDING: {
            type: TagType.Success,
            label: this.translate.instant('pages.validation.pending'),
          },
          null: {
            type: TagType.Info,
            label: null,
          },
        },
      },
    },
    {
      key: 'vehicleCode',
      label: this.translate.instant('pages.validation.vehicle'),
      type: TableElementType.Text,
    },
    {
      key: 'networkId',
      label: this.translate.instant('pages.validation.network'),
      type: TableElementType.Text,
      modifier: (networkId) => {
        if (!networkId) return;
        const networks = this.auth.networks.concat(this.$$networks);
        let network = networks.find((network) => +network.id === +networkId);
        return network?.name || `${networkId}`;
      },
    },
    {
      key: 'validationNetworkId',
      label: this.translate.instant('pages.validation.network_validation'),
      type: TableElementType.Text,
      modifier: (validationNetworkId) => {
        if (!validationNetworkId) return;
        const networks = this.auth.networks.concat(this.$$networks);
        const network = networks.find((network) => +network.id === +validationNetworkId);
        return network?.name || `${validationNetworkId}`;
      },
    },
    {
      key: 'provider',
      label: this.translate.instant('otherslabels.col_platform'),
      type: TableElementType.Image,
      modifier: (provider) =>
        match(provider)
          .with('tixipass', () => '/assets/img/logo_tixipass.png')
          .with('instantsystem', () => '/assets/img/providers/instant_system.png')
          .when(
            (provider) => ['sncf', 'smt_nfc', 'modalis'].includes(provider),
            (provider) => `/assets/img/providers/${provider}.png`
          )
          .otherwise(() => ''),
      config: { width: '66%' },
    },
    {
      key: 'tripOriginLabel',
      label: this.translate.instant('otherslabels.col_origin'),
      type: TableElementType.Text,
    },
    {
      key: 'tripDestinationLabel',
      label: this.translate.instant('otherslabels.col_destination'),
      type: TableElementType.Text,
    },
    {
      key: 'stopCode',
      label: this.translate.instant('pages.validation.stop_code'),
      type: TableElementType.Text,
    },
    {
      key: 'lineCode',
      label: this.translate.instant('pages.validation.line'),
      type: TableElementType.Text,
    },
    {
      key: 'mediaType',
      label: this.translate.instant('pages.validation.media_type'),
      type: TableElementType.Icon,
      modifier: (mediaType) =>
        match(mediaType)
          .when(
            (mediaType) => ['CARD', 'PASS', 'QR', 'APP'].includes(mediaType),
            () => mediaType
          )
          .otherwise(() => 'UNKNOWN'),
      config: {
        source: 'heroicons',
        labels: {
          CARD: this.translate.instant('pages.validators_validations.media_card'),
          PASS: this.translate.instant('pages.validators_validations.media_pass'),
          QR: this.translate.instant('pages.validators_validations.media_qr'),
          APP: this.translate.instant('pages.validators_validations.media_app'),
          UNKNOWN: this.translate.instant('pages.validators_validations.media_unknow'),
        },
        icons: {
          CARD: heroicons.outline.creditCard,
          PASS: heroicons.outline.ticket,
          QR: heroicons.outline.qrcode,
          APP: heroicons.outline.deviceMobile,
          UNKNOWN: heroicons.outline.questionMarkCircle,
        },
      },
    },
    {
      key: 'mediaId',
      label: this.translate.instant('pages.validation.media'),
      type: TableElementType.Text,
    },
  ];
  //Validations v2
  public walletEventsTableConfig = [
    {
      key: 'contractId',
      label: this.translate.instant('pages.validation.title_id'),
      type: TableElementType.Link,
      modifier: (contractId: number) => {
        return {
          link: ['/ticket', 'v2', contractId],
          label: contractId,
        };
      },
      class: { row: 'tw-text-xs' },
    },
    {
      key: 'contractId',
      label: this.translate.instant('pages.validation.title'),
      type: TableElementType.Text,
      modifier: (contractId: string) => {
        if (this.$$contracts.length > 0) {
          const contract = this.$$contracts.find((contract) => contract.id === contractId);
          return contract?.blueprintName || '-';
        }
      },
    },
    {
      key: 'occurredAt',
      label: this.translate.instant('pages.validation.date_time'),
      type: TableElementType.Date,
      format: 'medium',
    },
    {
      key: 'type',
      label: this.translate.instant('pages.validation.type'),
      type: TableElementType.Tag,
      modifier: (type: string) =>
        match(type)
          .when(
            (type) => ['TRANSFER', 'PUNCH'].includes(type),
            () => type
          )
          .otherwise(() => 'UNKNOWN'),
      config: {
        tags: {
          TRANSFER: {
            type: TagType.Success,
            label: this.translate.instant('pages.validation.transfer'),
          },
          PUNCH: { type: TagType.Danger, label: this.translate.instant('pages.validation.punch') },
          UNKNOWN: { type: TagType.Info, label: '' },
        },
      },
    },
    {
      key: 'occurredAt',
      label: this.translate.instant('pages.validation.offline'),
      type: TableElementType.Tag,
      modifier(occuredAt, walletEvent: CustomerWalletEvent) {
        const createdAtDate = new Date(walletEvent.createdAt);
        const occuredAtDate = new Date(occuredAt);

        return differenceInMinutes(createdAtDate, occuredAtDate) === 0 ? 'ONLINE' : 'OFFLINE';
      },
      config: {
        tags: {
          OFFLINE: {
            type: TagType.Success,
            label: this.translate.instant('otherslabels.yes'),
          },
          ONLINE: { type: TagType.Danger, label: this.translate.instant('otherslabels.no') },
        },
      },
      class: { row: 'tw-text-center' },
    },
    {
      key: 'contractNetworkId',
      label: this.translate.instant('pages.validation.network'),
      type: TableElementType.Text,
      modifier: (contractNetworkId) => {
        if (!contractNetworkId) return;
        const networks = this.auth.networks.concat(this.$$networks);
        const network = networks.find((network) => +network.id === +contractNetworkId);
        return network?.name || `${contractNetworkId}`;
      },
    },
    {
      key: 'contractId',
      label: this.translate.instant('otherslabels.col_origin'),
      type: TableElementType.Text,
      modifier: (contractId) => {
        const contract = this.$$contracts.find(({ id }) => id === contractId);

        if (contract && contract?.trip?.origin?.label) {
          return contract.trip.origin.label;
        }
      },
    },
    {
      key: 'contractId',
      label: this.translate.instant('otherslabels.col_destination'),
      type: TableElementType.Text,
      modifier: (contractId) => {
        const contract = this.$$contracts.find(({ id }) => id === contractId);

        if (contract && contract?.trip?.destination?.label) {
          return contract.trip.destination.label;
        }
      },
    },
    {
      key: 'location',
      label: this.translate.instant('pages.validation.stop'),
      type: TableElementType.Text,
      modifier: (location) => location?.stationName || location?.stationCode,
    },
    {
      key: 'location',
      label: this.translate.instant('pages.validation.line'),
      type: TableElementType.Text,
      modifier: (location) => location?.lineName || location?.lineCode,
    },
    {
      key: 'location',
      label: this.translate.instant('pages.validation.course'),
      type: TableElementType.Text,
      modifier: (location) => location?.course,
    },
    {
      key: 'location',
      label: this.translate.instant('pages.validation.direction'),
      type: TableElementType.Text,
      modifier: (location) => location?.tripDirection,
    },
  ];

  public showCustomerOrders: boolean = false;

  public customerOrders: {
    productId: string;
    productName: string;
    purchaseDate: string;
    price: number;
    orderId: string;
    orderCode: string;
    recipient: {
      id: string;
      firstName: string;
      lastName: string;
    };
    network: Network | string;
    status: CustomerOrderStatus;
    reservation: ArticleBooking | null;
    quantity: number;
  }[] = [];

  public customerOrdersTableConfig = [
    {
      key: 'productId',
      label: this.translate.instant('pages.customer_details.order_productid'),
      type: TableElementType.Link,
      modifier: (productId) => {
        return {
          link: ['/shop', 'products', 'edit', productId],
          label: productId,
        };
      },
    },
    {
      key: 'productName',
      label: this.translate.instant('pages.customer_details.order_productname'),
      type: TableElementType.Text,
    },
    {
      key: 'purchaseDate',
      label: this.translate.instant('pages.customer_details.order_purchasedate'),
      type: TableElementType.Date,
      format: 'short',
    },
    {
      key: 'quantity',
      label: this.translate.instant('pages.order.article_quantity'),
      type: TableElementType.Text,
      modifier: (quantity) => {
        return quantity ?? 1;
      },
    },
    {
      key: 'price',
      label: this.translate.instant('pages.order.article_unit_price'),
      type: TableElementType.Price,
      modifier: (price) => {
        const unitPrice = +(price || 0);

        return unitPrice;
      },
      currency: (article) => article.network?.currency || 'EUR',
      locale: () => this.translate.currentLang,
    },
    {
      key: 'price',
      label: this.translate.instant('pages.order.article_total_price'),
      type: TableElementType.Price,
      modifier: (price, article) => {
        const quantity = article?.quantity ?? 1;
        const unitPrice = +(price || 0);

        return quantity * unitPrice;
      },
      currency: (article) => article.network?.currency || 'EUR',
      locale: () => this.translate.currentLang,
    },
    {
      key: 'orderId',
      label: this.translate.instant('pages.customer_details.order_orderid'),
      type: TableElementType.Link,
      modifier: (orderId, article) => {
        return {
          link: ['/orders', orderId],
          label: article.orderCode,
        };
      },
    },
    {
      key: 'recipient',
      label: this.translate.instant('pages.customer_details.order_recipient'),
      type: TableElementType.Link,
      modifier: (recipient) => {
        return {
          link: recipient.id ? ['/customers', recipient.id] : null,
          label: `${recipient.firstName} ${recipient.lastName}`,
        };
      },
    },
    {
      key: 'network',
      label: this.translate.instant('pages.customer_details.order_network'),
      type: TableElementType.Text,
      modifier: (network) => {
        return network?.name || network;
      },
    },
    {
      key: 'status',
      label: this.translate.instant('pages.customer_details.status'),
      type: TableElementType.Tag,
      config: {
        tags: {
          COMPLETED: { type: TagType.Success, label: this.translate.instant('pages.order.ok') },
          ERROR: { type: TagType.Danger, label: this.translate.instant('pages.order.refused') },
          PENDING: { type: TagType.Info, label: this.translate.instant('pages.order.unfinished') },
          CANCELED: {
            type: TagType.Default,
            label: this.translate.instant('pages.order.cancelled'),
          },
          REGULARIZATION: {
            type: TagType.Default,
            label: this.translate.instant('pages.order.regularization'),
          },
          TO_REFUND: {
            type: TagType.Warning,
            label: this.translate.instant('pages.order.to_refund'),
          },
          REFUNDED: {
            type: TagType.Default,
            label: this.translate.instant('pages.order.refunded'),
          },
          PAYMENT_ISSUE: {
            type: TagType.Danger,
            label: this.translate.instant('pages.order.default_payment'),
          },
          PREAUTHORIZED: {
            type: TagType.Success,
            label: this.translate.instant('pages.order.preauthorized'),
          },
          WAITING_FOR_PAYMENT: {
            type: TagType.Warning,
            label: this.translate.instant('pages.order.waiting_for_payment'),
          },
          PROCESSING: {
            type: TagType.Warning,
            label: this.translate.instant('pages.order.processing'),
          },
        },
      },
    },
    {
      key: '',
      label: this.translate.instant('pages.order_details.product_type'),
      type: TableElementType.Text,
      modifier: (_, article) => {
        if (article.reservation) {
          return this.translate.instant('pages.order_details.booking');
        }

        return this.translate.instant('pages.order_details.default_product');
      },
    },
    {
      key: '',
      label: this.translate.instant('pages.customer_details.order_booking'),
      type: TableElementType.Text,
      modifier: (_, article) => {
        if (article.reservation) {
          return this.getHtmlBelongingCell(article.reservation);
        }

        return;
      },
    },
  ];

  public mediaTableConfig = [
    {
      key: 'code',
      label: this.translate.instant('pages.customer_details.card_number'),
      type: TableElementType.Text,
    },
    {
      key: 'token',
      label: this.translate.instant('pages.customer_details.media_id'),
      type: TableElementType.Text,
    },
    {
      key: 'card_type',
      label: this.translate.instant('pages.customer_details.card_type'),
      type: TableElementType.Text,
      modifier: (type: string) => {
        switch (type) {
          case 'CIPURSE':
            return 'CiPurse';
          case 'CALYPSO':
            return 'Calypso';
          case 'DESFIRE':
            return 'DesFire';
          case 'ACTOLL_MIFARE_CLASSIC':
            return 'Actoll - Mifare Classic';
          case 'ACTOLL_CALYPSO':
            return 'Actoll - Calypso';
          case 'DIRECT_PAYMENT_INGENICO_FRANCE':
            return 'Ingenico France';
          case 'OPEN_PAYMENT_INGENICO_OP2GO':
            return 'Ingenico OP2GO';
          case 'WORDLINE_TAP2USE':
            return 'WorldLine Tap2Use';

          default:
            return type || null;
        }
      },
    },
    {
      key: 'created_at',
      label: this.translate.instant('pages.customer_details.created_at'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date ? this.dateFormat.transform(date, 'DD-MM-YYYY') : null;
      },
    },
    {
      key: 'expires_at',
      label: this.translate.instant('pages.network_details.expire_at'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date ? this.dateFormat.transform(date, 'DD-MM-YYYY') : null;
      },
    },
  ];

  public docTableConfig = [
    {
      key: 'name',
      label: this.translate.instant('pages.customer_details.document'),
      type: TableElementType.Text,
      style: (document) => {
        return document.status === 'EXPIRED' ? 'text-decoration: line-through' : '';
      },
    },
    {
      key: 'status',
      label: this.translate.instant('pages.customer_details.status'),
      type: TableElementType.Select,
      style: 'min-width: 160px;',
      config: {
        disabled: (value: string) => {
          return value === 'EXPIRED';
        },
        options: [
          {
            type: TagType.Success,
            label: this.translate.instant('pages.network_details.valid'),
            value: 'VALID',
          },
          {
            type: TagType.Danger,
            label: this.translate.instant('pages.network_details.invalid'),
            value: 'INVALID',
          },
          {
            type: TagType.Info,
            label: this.translate.instant('otherslabels.select_waiting'),
            value: 'PENDING',
          },
          {
            type: TagType.Danger,
            label: this.translate.instant('otherslabels.select_expired'),
            value: 'EXPIRED',
            disabled: true,
          },
          {
            type: TagType.Danger,
            label: this.translate.instant('otherslabels.select_processing'),
            value: 'VALIDATING',
          },
        ],
      },
      change: (status, doc: Document) => {
        try {
          this.paperworkService
            .updateDocument(doc.id, {
              status,
            })
            .subscribe(() => {
              this.notification.success(
                this.translate.instant('pages.customer_details.document_edit_notif')
              );
            });
        } catch {
          this.notification.error(this.translate.instant('otherslabels.generic_error'));
        }
      },
    },
    {
      key: 'expires_at',
      label: this.translate.instant('pages.network_details.expire_at'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date
          ? this.dateFormat.transform(date, 'DD-MM-YYYY')
          : this.translate.instant(`pages.customer_details.undefined`);
      },
    },
    {
      key: 'id',
      label: this.translate.instant('pages.customer_details.download_label'),
      type: TableElementType.Function,
      modifier: (id: string, customerDocument: Document) => {
        return {
          icon:
            customerDocument.status !== 'EXPIRED' &&
              !customerDocument.deleted_at &&
              customerDocument.file_id
              ? 'icon-cloud-download'
              : '',
          fn: async () => {
            try {
              const url = await firstValueFrom(this.submissionService.getDocumentDetail(+id));

              if (!url) return;

              const link = document.createElement('a');
              link.href = url;
              link.download = customerDocument.name;
              link.target = '_blank';
              link.dispatchEvent(new MouseEvent('click'));
            } catch (error) {
              return;
            }
          },
        };
      },
    },
    {
      key: 'deleted_at',
      label: this.translate.instant('pages.customer_details.delete'),
      type: TableElementType.Function,
      modifier: (deleteDate: string, document: Document) => {
        return {
          icon: deleteDate || !document.file_id ? '' : 'trash-o',
          fn: this.openDeleteDocumentModal.bind(this),
        };
      },
      displayed: this.permissionGuard.isOnePermissionAllowed(['DELETE_SUBMISSION_DOCUMENT']),
    },
    {
      key: 'status',
      label: this.translate.instant('pages.customer_details.update'),
      type: TableElementType.Function,
      modifier: (status: string, doc: any) => {
        return {
          icon: status === 'INVALID' && doc.file_id ? 'cloud-upload' : '',
          fn: () => {
            this.openUpdateDocumentModal(doc);
          },
        };
      },
      displayed: this.permissionGuard.isOnePermissionAllowed(['DELETE_SUBMISSION_DOCUMENT']),
    },
  ];

  public deletedDocTableConfig = [
    {
      key: 'name',
      label: this.translate.instant('pages.customer_details.document'),
      type: TableElementType.Text,
      style: (document) => {
        return document.status === 'EXPIRED' ? 'text-decoration: line-through' : '';
      },
    },
    {
      key: 'status',
      label: this.translate.instant('pages.customer_details.status'),
      type: TableElementType.Tag,
      config: {
        tags: {
          VALID: {
            type: TagType.Success,
            label: this.translate.instant('pages.network_details.valid'),
          },
          INVALID: {
            type: TagType.Danger,
            label: this.translate.instant('pages.network_details.invalid'),
          },
          EXPIRED: {
            type: TagType.Danger,
            label: this.translate.instant('pages.customer_details.expired'),
          },
          PENDING: {
            type: TagType.Info,
            label: this.translate.instant('pages.customer_details.status_pending'),
          },
        },
      },
    },
    {
      key: 'file_deleted_at',
      label: this.translate.instant('pages.customer_details.deleted_at'),
      type: TableElementType.Text,
      modifier: (date: string) => {
        return date
          ? this.dateFormat.transform(date, 'DD-MM-YYYY')
          : this.translate.instant(`pages.customer_details.undefined`);
      },
    },
  ];

  public submissionsTableConfig = [
    {
      key: 'id',
      label: 'Id',
      type: TableElementType.Link,
      modifier: (id) => {
        return {
          link: ['/submissions', id],
          label: id,
        };
      },
    },
    {
      key: 'doc_types',
      label: this.translate.instant('pages.customer_details.doc_types'),
      type: TableElementType.Text,
    },
    {
      key: 'product',
      label: this.translate.instant('pages.submissions.product'),
      type: TableElementType.Text,
      isSortable: true,
    },
    {
      key: 'status',
      label: this.translate.instant('pages.submissions.status'),
      type: TableElementType.Tag,
      config: {
        tags: {
          CANCELED: {
            type: TagType.Default,
            label: this.translate.instant('pages.submissions.cancelled'),
          },
          REJECTED: {
            type: TagType.Danger,
            label: this.translate.instant('pages.submissions.rejected'),
          },
          VALIDATED: {
            type: TagType.Success,
            label: this.translate.instant('pages.submissions.validated'),
          },
          PENDING: {
            type: TagType.Info,
            label: this.translate.instant('pages.submissions.pending'),
          },
          PROCESSING: {
            type: TagType.Warning,
            label: this.translate.instant('pages.submissions.processing'),
          },
          ON_HOLD: {
            type: TagType.Default,
            label: this.translate.instant('pages.submissions.on_hold'),
          },
        },
      },
      isSortable: true,
    },
    {
      key: 'date',
      label: this.translate.instant('pages.customer_details.created_at'),
      type: TableElementType.Date,
      exportable: true,
      format: 'mediumDate',
      isSortable: true,
    },
    {
      key: 'network_id',
      label: this.translate.instant('pages.submissions.network'),
      type: TableElementType.Text,
      modifier: (networkId: string) => {
        const network = this.auth.getNetwork(networkId);

        return network?.name ?? '-';
      },
      isSortable: true,
    },
  ];

  public voucherTableConfig = [
    {
      key: 'code',
      label: this.translate.instant('pages.customer_details.code'),
      type: TableElementType.Text,
    },
    {
      key: 'expire_at',
      label: this.translate.instant('pages.network_details.expire_at'),
      type: TableElementType.Text,
      modifier: (date) => {
        return date
          ? this.dateFormat.transform(date, 'DD-MM-YYYY')
          : this.translate.instant(`pages.customer_details.undefined`);
      },
    },
  ];

  public subscriptionTableConfig = [
    {
      key: 'id',
      label: 'Id',
      type: TableElementType.Link,
      modifier: (subscriptionId: string) => {
        return {
          link: ['/subscriptions', subscriptionId],
          label: subscriptionId,
        };
      },
    },
    {
      key: 'order_id',
      label: this.translate.instant('pages.order.sale_id'),
      type: TableElementType.Link,
      modifier: (orderId: string) => {
        return {
          link: ['/orders', orderId],
          label: orderId,
        };
      },
    },
    {
      key: 'item_id',
      label: this.translate.instant('pages.subscriptions.product'),
      type: TableElementType.Link,
      modifier: (itemId: number) => {
        const product = this.$$products.find((product) => product.id === `${itemId}`);

        return {
          link: ['/shop/products/edit/', itemId],
          label: product?.name ?? itemId,
        };
      },
    },
    {
      key: 'created_at',
      label: this.translate.instant('pages.subscriptions.purchase_date'),
      type: TableElementType.Text,
      modifier: (date) => {
        return date
          ? this.dateFormat.transform(date, 'DD-MM-YYYY')
          : this.translate.instant(`pages.customer_details.undefined`);
      },
    },
    {
      key: 'starting_at',
      label: this.translate.instant('pages.subscriptions.start_date'),
      type: TableElementType.Text,
      modifier: (date) => {
        return date
          ? this.dateFormat.transform(date, 'DD-MM-YYYY')
          : this.translate.instant(`pages.customer_details.undefined`);
      },
    },
    {
      key: 'updated_at',
      label: this.translate.instant('pages.subscriptions.update'),
      type: TableElementType.Text,
      modifier: (date) => {
        return date
          ? this.dateFormat.transform(date, 'DD-MM-YYYY')
          : this.translate.instant(`pages.customer_details.undefined`);
      },
    },
    {
      key: 'disabled_at',
      label: this.translate.instant('pages.subscriptions.termination_date'),
      type: TableElementType.Text,
      modifier: (date) => {
        return date
          ? this.dateFormat.transform(date, 'DD-MM-YYYY')
          : this.translate.instant(`pages.customer_details.undefined`);
      },
    },
    {
      key: 'termination_origin',
      label: this.translate.instant('pages.customer_details.termination_origin'),
      type: TableElementType.Tag,
      modifier: (terminationOrigin) => {
        if (!terminationOrigin) {
          return 'UNDEFINED';
        }

        return terminationOrigin;
      },
      config: {
        tags: {
          OPERATOR: {
            type: TagType.Info,
            label: this.translate.instant('pages.customer_details.operator'),
          },
          CUSTOMER: {
            type: TagType.Info,
            label: this.translate.instant('pages.customer_details.customer'),
          },
          UNDEFINED: {
            type: TagType.Default,
            label: this.translate.instant('pages.customer_details.undefined'),
          },
          SCRIPT: { type: TagType.Info, label: this.translate.instant('otherslabels.other') },
        },
      },
    },
    {
      key: 'network_id',
      label: this.translate.instant('pages.order.network'),
      type: TableElementType.Text,
      modifier: (id: string) => this.auth.networks.find((n) => +n.id === +id).name,
    },
    {
      key: 'id',
      label: this.translate.instant('pages.customer_details.action'),
      type: TableElementType.Submenu,
      config: {
        icon: heroicons.outline.dotsVertical,
        actions: [
          {
            label: this.translate.instant('pages.customer_details.unsubscribe'),
            fn: async (subscription) => {
              await this.disableSubscription(subscription.id);

              // Send notif
              const product = this.$$products.find(
                (product) => product.id === `${subscription.item_id}`
              );

              this.notification.success(
                this.translate.instant('pages.customer_details.unsubscribe_notif', {
                  name: product.name,
                })
              );
            },
            active: (subscription) => {
              return !subscription.disabled_at;
            },
          },
          {
            label: this.translate.instant('pages.customer_details.cancel_unsubscribe'),
            fn: async (subscription) => {
              await this.subscriptionService.enableSubscription(subscription.id);

              // Update data
              // HACK: The table component doesn't update when only one element is updated, so I have to retrieve the list to update the table.
              // It's not pretty, but I couldn't find any other solution.
              this.retrieveSubscriptions(this.$$customer.customer_id);

              // Send notif
              const product = this.$$products.find(
                (product) => product.id === `${subscription.item_id}`
              );

              this.notification.success(
                this.translate.instant('pages.customer_details.cancel_unsubscribe_notif', {
                  name: product.name,
                })
              );
            },
            active: (subscription) => {
              return subscription.disabled_at;
            },
          },
        ],
      },
    },
  ];

  public attributesTableConfig = [
    {
      key: 'label',
      label: this.translate.instant('pages.customer_details.description'),
      type: TableElementType.Text,
      modifier: (label: string) => this.translate.instant(label),
    },
    {
      key: 'network_id',
      label: this.translate.instant('pages.customer_details.network'),
      type: TableElementType.Text,
      displayed: () => this.attributeNetworks.length > 1,
      style: (attribute: Attribute) => (attribute.network_id === null ? 'opacity: 0.2;' : null),
      modifier: (networkId: string) => {
        if (!networkId) return this.translate.instant('pages.customer_details.global');
        const network = this.auth.getNetwork(networkId);
        if (!network) return 'Unknown'; // not supposed to happen

        return network.name;
      },
    },
    {
      key: 'key',
      label: this.translate.instant('pages.customer_details.value'),
      type: TableElementType.Text,
      style: (customerAttribute: Attribute) => {
        const attribute = this.$$attributes.find(
          (attribute) => attribute.key === customerAttribute.key
        );
        if (!attribute) return 'opacity: 0.2;';
        if (attribute.value === null) return 'opacity: 0.2;';
        if (attribute.value.length < 1) return 'opacity: 0.2;';

        return null;
      },
      modifier: (attributeKey: string) => {
        const attribute = this.$$attributes.find((attribute) => attribute.key === attributeKey);
        if (!attribute) return this.translate.instant('pages.customer_details.empty');
        if (attribute.value === null) return this.translate.instant('pages.customer_details.empty');
        if (attribute.value === false) return this.translate.instant('otherslabels.no');
        if (attribute.value === true) return this.translate.instant('otherslabels.yes');
        if (attribute.value.length < 1) {
          return this.translate.instant('pages.customer_details.empty');
        }

        return attribute.value;
      },
    },
    {
      key: 'key',
      label: this.translate.instant('pages.customer_details.edition'),
      type: TableElementType.Function,
      modifier: (attributeKey: string) => {
        const globalAttribute = this.$$allAttributes.find(
          (attribute) => attribute.key === attributeKey
        );

        return {
          icon: 'icon-pencil',
          fn: () => {
            const customerAttribute = this.$$attributes.find(
              (attribute) => globalAttribute.key === attribute.key
            );

            const attributeData: DialogData = {
              customerId: this.$$customer.customer_id,
              label: this.translate.instant(globalAttribute.label),
              value: customerAttribute ? customerAttribute.value : undefined,
              attributeId: globalAttribute.id,
              attributeType: globalAttribute.type,
              attributeValues:
                globalAttribute.type === 'BOOLEAN'
                  ? [true, false]
                  : globalAttribute.values?.sort((a, b) => a.localeCompare(b)).filter(Boolean),
            };

            this.openAttributesModal(attributeData);
          },
        };
      },
      displayed: this.permissionGuard.isOnePermissionAllowed(['MANAGE_CUSTOMER_ATTRIBUTES']),
    },
  ];

  public sessionTableConfig = [
    {
      key: 'date',
      label: this.translate.instant('pages.customer_details.lastconnections_date'),
      type: TableElementType.Date,
      format: 'short',
    },
    {
      key: 'originType',
      label: this.translate.instant('pages.customer_details.lastconnections_origintype'),
      type: TableElementType.Text,
    },
    {
      key: 'originAgent',
      label: this.translate.instant('pages.customer_details.lastconnections_originagent'),
      type: TableElementType.Text,
      modifier: (originAgent: string) => {
        if (!originAgent) return null;

        // Get the OS info from the first part between parenthesis
        const regex = /\((.*?)\)/;
        const results = regex.exec(originAgent);
        if (!results?.length) return null;

        const osInfo = results[0].replace(/\(|\)/g, '');

        return osInfo;
      },
    },
    {
      key: 'originAgent',
      label: this.translate.instant('pages.customer_details.lastconnections_originname'),
      type: TableElementType.Text,
      modifier: (originAgent: string) => {
        if (!originAgent) return null;

        // Get the app info from the origin agent last part
        const regex = /\([^)]*\)/g;
        const userAgentWithoutParenthesisParts = originAgent.replace(regex, '');
        if (!userAgentWithoutParenthesisParts) return null;

        const userAgentParts = userAgentWithoutParenthesisParts
          .split(' ')
          .filter((part) => part !== '');
        if (!userAgentParts?.length) return null;

        const appInfo = userAgentParts[userAgentParts.length - 1];

        return appInfo;
      },
    },
  ];

  /**
   * Get a list of unique network Ids in visible attributes
   */
  get attributeNetworks(): any[] {
    return this.$$attributes.reduce((networks, attribute) => {
      const isGlobal = attribute.network_id === null;
      const isAlreadyCounted = networks.includes(attribute.network_id);

      if (isGlobal || isAlreadyCounted) {
        return networks;
      }

      return [...networks, attribute.network_id];
    }, []);
  }

  public get canBeAnonymized() {
    return this.$$customer?.active !== '1' && !this.$$customer?.anonymized_at;
  }

  public get isCustomerActiveOrAnonymized() {
    return this.$$customer.active === '1' || this.$$customer?.anonymized_at;
  }

  public get isCustomerActive() {
    return (
      ['true', '1', 1, true].includes(this.$$customer.active) && !this.$$customer?.anonymized_at
    );
  }

  public get isCustomerParent() {
    const profile = this.$$profiles.find(
      (profile) => profile.customer_id === this.$$customer.customer_id
    );
    return profile?.isParent;
  }

  public get areTicketsSelected() {
    if (!this.$$selectedTicketRows.length) {
      return false;
    }
    if (this.activeTicketsTab === 'v1' && this.$$selectedTicketRows[0].elem?.ticket_id) {
      return true;
    }
    if (this.activeTicketsTab === 'v2' && this.$$selectedTicketRows[0].elem?.blueprintId) {
      return true;
    }
    this.$$selectedTicketRows = [];
    return false;
  }

  public get operatorHasSameNetworks() {
    if (this.isSuperAdmin) return true;

    const networkIds = this.auth.networks.map(({ id }) => Number.parseInt(id, 10));

    return this.$$customer.network_ids.every((id) => {
      return networkIds.includes(id);
    });
  }

  public filterWalletContent(ticketsContracts: $$Ticket[] | Contract[]): $$Ticket[] | Contract[] {
    if (!ticketsContracts || ticketsContracts.length === 0) return null;

    const filterForm = this.filterForm.value;
    const isTicket = ticketsContracts[0]?.hasOwnProperty('network_id');
    const filterKey = isTicket ? 'network_id' : 'networkId';

    //Network filter
    const networkFilteredContent = this.keyPipe.transform(
      ticketsContracts as any[],
      filterKey,
      filterForm.customerNetwork
    );

    //Text search filter
    const searchFilteredContent = this.searchPipe.transform(
      networkFilteredContent,
      filterForm.customerQuery
    );

    //Status filter
    const statusFilteredContent = searchFilteredContent.filter((ticketContract) => {
      if (filterForm.status === null) return true;
      //Ticket
      if (isTicket) return ticketContract.status === filterForm.status;
      //Contract
      const status = this._contractStatus(ticketContract);
      return match(filterForm.status)
        .with('ACTIVE', () => status === CONTRACT_STATUS.ACTIVE)
        .with('PENDING', () => status === CONTRACT_STATUS.PENDING)
        .with('REMAINING', () => status === CONTRACT_STATUS.REMAINING)
        .with('EXPIRED', () => status === CONTRACT_STATUS.EXPIRED)
        .otherwise(() => false);
    });

    //Origin filter
    const originFilteredContent = statusFilteredContent.filter((ticketOrContract) => {
      if (filterForm.origin === null) return true;
      return match(filterForm.origin)
        .with('SHOP', () => !!!ticketOrContract.generationReason)
        .with('OPERATOR', () => !!ticketOrContract.generationReason)
        .otherwise(() => true);
    });

    return originFilteredContent;
  }

  private canceledStyle(style, ticket) {
    if (['CANCELED', 'CANCELLED'].includes(ticket.status)) return style;
  }

  private reactivateStyle(style, ticket) {
    if (!['CANCELED', 'CANCELLED'].includes(ticket.status)) return style;
  }

  private canceledContractStyle(style, contract) {
    if (!contract.blockedAt) return;

    const blockDate = new Date(contract.blockedAt);
    const now = new Date();

    if (blockDate < now) return style;
  }

  private reactivateContractStyle(style, contract) {
    if (!contract.blockedAt) return style;
  }

  private $$populateNetworks(networkIds: number[]) {
    const networks = networkIds
      .map((networkId) => {
        return this.auth.getNetwork(`${networkId}`);
      })
      .filter(Boolean);
    this.updateCustomerNetworks(networks);

    return networks;
  }

  async ngOnInit() {
    const userSession = await this.auth.session();
    this.isSuperAdmin = userSession?.user?.roles.some((role) => role.name === 'SUPER_ADMIN');

    // Whenever the route params change, we fetch the customer
    this.route.params.pipe(distinctUntilChanged()).subscribe((params) => {
      this.customerId = params.id;

      this.$$customer = null;
      this.$$loadingCustomer = true;

      this.$$profiles = [];
      this.$$loadingProfiles = true;

      this.$$wallet = [];
      this.$$filteredWallet = [];
      this.$$loadingWallet = true;
      this.$$errorWallet = false;
      this.$$errorContracts = false;
      this.$$selectedTicketRows = [];

      this.$$contracts = [];
      this.$$filteredContracts = [];
      this.$$loadingContracts = true;

      this.$$medias = [];
      this.$$loadingMedias = true;

      this.$$subscriptions = [];
      this.$$loadingSubscriptions = true;

      this.$$networks = [];
      this.$$isAddingNetwork = false;
      this.$$newNetworkId = '';
      this.$$newNetWorkLoading = false;
      this.$$customerNetworks = null;

      this.$$documents = [];
      this.$$deletedDocuments = [];
      this.$$loadingDocuments = true;

      this.$$submissions = [];
      this.$$loadingSubmissions = true;

      this.$$vouchers = [];
      this.$$loadingVouchers = true;

      this.$$allAttributes = [];
      this.$$loadingAllAttributes = true;

      this.$$attributes = [];
      this.$$loadingAttributes = true;

      this.$$comments = [];
      this.$$loadingComments = true;
      this.$$selectedCommentNetwork = '';

      this.$$products = [];
      this.$$loadingProducts = true;

      this.customerOrders = [];
      this.customer_history = [];

      this.$$validations = [];
      this.$$loadingValidations = true;
      this.$$walletEvents = [];
      this.$$loadingwalletEvents = true;

      this.computeBookingFilters();

      this.customersService
        .$$getCustomer(params.id)
        .then(async (customer) => {
          const allowedNetworks = customer.network_ids.filter((networkId) => {
            return this.auth.networks.some((network) => {
              return `${network.id}` === `${networkId}`;
            });
          });

          this.shopService.countNetworksProductTypes(allowedNetworks).then((countV1ProductType) => {
            this.isTicketV1Enabled = Boolean(countV1ProductType);
          });

          // Handle the case where the customer has a facebook profile picture
          const match = this.facebookRegex.exec(customer.picture);

          customer['fb_url'] = match ? `https://www.facebook.com/${match[1]}` : null;

          this.$$customer = customer;
          this.$$networks = this.$$populateNetworks(customer.network_ids);
          this.$$loadingCustomer = false;

          const allAttributes = await this.customersService.$$getGlobalAttributes();

          const attributes = allAttributes.filter((attribute) => {
            const isGlobal = attribute.network_id === null;
            if (isGlobal) return true;

            const isInCustomerNetworks = customer.network_ids.includes(+attribute.network_id);
            return isInCustomerNetworks;
          });

          this.$$allAttributes = attributes;
          this.$$loadingAllAttributes = false;

          // Orders history
          this.computeOrderHistoryFilter();

          // Sessions
          if (this.isSuperAdmin) {
            this.sessions = await this.customersService.getCustomerSessions(params.id);
          }
        })
        .catch(({ error }) => {
          switch (error.code) {
            case 'CUSTOMER_NOT_FOUND':
              return this.router.navigate(['/404']);
            case 'CUSTOMER_FORBIDDEN':
              return this.router.navigate(['/403']);
            default:
              return this.router.navigate(['/500']);
          }
        });

      this.customersService
        .$$getProfiles(params.id)
        .then((profiles) => {
          this.$$profiles = profiles;

          this.retrieveSubscriptions(params.id);
        })
        .catch(({ error }) => {
          switch (error.code) {
            case 'CUSTOMER_NOT_FOUND':
              return this.router.navigate(['/404']);
            case 'CUSTOMER_FORBIDDEN':
              return this.router.navigate(['/403']);
            default:
              return this.router.navigate(['/500']);
          }
        })
        .finally(() => {
          this.$$loadingProfiles = false;
        });

      this.customersService
        .$$getWallet(params.id)
        .then((tickets) => {
          this.$$wallet = tickets;
          this.$$filteredWallet = this.filterWalletContent(tickets) as $$Ticket[];
          this.computeContractsStats();
          this._updateCMSUserList().finally(() => {
            this.$$loadingWallet = false;
          });
        })
        .catch(({ error }) => {
          switch (error.code) {
            case 'CUSTOMER_NOT_FOUND':
              return this.router.navigate(['/404']);
            case 'CUSTOMER_FORBIDDEN':
              return this.router.navigate(['/403']);
            default:
              this.$$errorWallet = true;
          }
        });

      if (this.permissionGuard.isOnePermissionAllowed(['ACCESS_PLATFORM'])) {
        this.customersService
          .getContracts(params.id)
          .then((contracts) => {
            this.$$contracts = contracts;
            this.$$filteredContracts = this.filterWalletContent(contracts) as Contract[];
            this.computeContractsStats();
            this._updateCMSUserList().finally(() => {
              this.$$loadingContracts = false;
            });
          })
          .catch(({ error }) => {
            switch (error.code) {
              case 'CUSTOMER_NOT_FOUND':
                return this.router.navigate(['/404']);
              case 'CUSTOMER_FORBIDDEN':
                return this.router.navigate(['/403']);
              default:
                this.$$errorContracts = true;
            }
          });
      }

      this.customersService
        .$$getMedias(params.id)
        .then((medias) => {
          this.$$medias = medias;
        })
        .catch(({ error }) => {
          switch (error.code) {
            case 'CUSTOMER_NOT_FOUND':
              return this.router.navigate(['/404']);
            case 'CUSTOMER_FORBIDDEN':
              return this.router.navigate(['/403']);
            default:
              return this.router.navigate(['/500']);
          }
        })
        .finally(() => {
          this.$$loadingMedias = false;
        });

      this.retrieveDocuments(params.id);

      this.submissionService
        .$$getUserSubmissions(params.id)
        .then((submissions) => {
          this.$$submissions = submissions;
        })
        .catch(({ error }) => {
          switch (error.code) {
            case 'CUSTOMER_NOT_FOUND':
              return this.router.navigate(['/404']);
            case 'CUSTOMER_FORBIDDEN':
              return this.router.navigate(['/403']);
            default:
              return this.router.navigate(['/500']);
          }
        })
        .finally(() => {
          this.$$loadingSubmissions = false;
        });

      this.customersService
        .$$getVouchers(params.id)
        .then((vouchers) => {
          this.$$vouchers = vouchers;
        })
        .catch(({ error }) => {
          switch (error.code) {
            case 'CUSTOMER_NOT_FOUND':
              return this.router.navigate(['/404']);
            case 'CUSTOMER_FORBIDDEN':
              return this.router.navigate(['/403']);
            default:
              return this.router.navigate(['/500']);
          }
        })
        .finally(() => {
          this.$$loadingVouchers = false;
        });

      this.customersService
        .getCustomerAttributes(params.id)
        .then((attributes) => {
          this.$$attributes = attributes;
        })
        .catch(({ error }) => {
          switch (error.code) {
            case 'CUSTOMER_NOT_FOUND':
              return this.router.navigate(['/404']);
            case 'CUSTOMER_FORBIDDEN':
              return this.router.navigate(['/403']);
            default:
              return this.router.navigate(['/500']);
          }
        })
        .finally(() => {
          this.$$loadingAttributes = false;
        });

      this.customersService
        .$$getComments(params.id)
        .then((comments) => {
          this.$$comments = comments;
        })
        .catch(({ error }) => {
          switch (error.code) {
            case 'CUSTOMER_NOT_FOUND':
              return this.router.navigate(['/404']);
            case 'CUSTOMER_FORBIDDEN':
              return this.router.navigate(['/403']);
            default:
              return this.router.navigate(['/500']);
          }
        })
        .finally(() => {
          this.$$loadingComments = false;
        });

      this.customersService
        .getHistory(params.id)
        .then((transfersHistory) => {
          //Transfers stats
          for (const transfers of transfersHistory?.transfers) {
            if (transfers.recipientCustomerId !== transfers.issuerCustomerId) {
              transfers.direction = +transfers.recipientCustomerId === +params.id ? 'IN' : 'OUT';
              this.statusType[`TRANSFER_${transfers.direction}`].count++;
              let recipient = null;
              let issuer = null;
              for (const customer_id in transfersHistory.customers) {
                const customer = transfersHistory.customers[customer_id];
                if (!issuer && +customer.id === +transfers.issuerCustomerId) {
                  issuer = customer;
                }
                if (!recipient && +customer.id === +transfers.recipientCustomerId) {
                  recipient = customer;
                }
                if (recipient && issuer) {
                  break;
                }
              }

              const issuerEmail = issuer.email ? `<br/> ${issuer.email}` : '';
              const recipientEmail = recipient.email ? `<br/> ${recipient.email}` : '';

              transfers.issuerCustomerLabel = issuer
                ? `${issuer.firstName} ${issuer.lastName} ${issuerEmail}`
                : '';
              transfers.recipientCustomerLabel = recipient
                ? `${recipient.firstName} ${recipient.lastName} ${recipientEmail}`
                : '';
              this.customer_history.push(transfers);
            }
          }
        })
        .catch(({ error }) => {
          switch (error.code) {
            case 'CUSTOMER_NOT_FOUND':
              return this.router.navigate(['/404']);
            case 'CUSTOMER_FORBIDDEN':
              return this.router.navigate(['/403']);
            default:
              return this.router.navigate(['/500']);
          }
        });

      //Validation v1
      this.customersService
        .getValidations(params.id)
        .then((validations) => {
          this.$$validations = validations.filter((validation) => {
            return this.auth.networks.some((n) => `${n.id}` === `${validation.networkId}`);
          });
        })
        .finally(() => {
          this.$$loadingValidations = false;
        });
      //Validation v2 (wallet events)
      this.customersService
        .getWalletEvents(params.id, [
          CustomerWalletEventType.PUNCH,
          CustomerWalletEventType.TRANSFER,
        ])
        .then((validations) => {
          this.$$walletEvents = validations;
        })
        .finally(() => {
          this.$$loadingwalletEvents = false;
        });
    });

    this.productsService
      .$$getProducts()
      .then((products) => {
        this.$$products = products;
      })
      .catch(({ error }) => {
        switch (error.code) {
          case 'CUSTOMER_NOT_FOUND':
            return this.router.navigate(['/404']);
          case 'CUSTOMER_FORBIDDEN':
            return this.router.navigate(['/403']);
          default:
            return this.router.navigate(['/500']);
        }
      })
      .finally(() => {
        this.$$loadingProducts = false;
      });

    // this.translate.onLangChange.subscribe(this.setTranslation);
  }

  /**
   * This method is used whenever the wallet (v1) or the contracts (v2) tickets
   * are being retrieved. It's used to calculate the states of the differents tickets
   * and aggregate them into little statistics.
   */
  public computeContractsStats() {
    // Reset all the stats to 0
    for (const key of Object.keys(this.statusType)) {
      this.statusType[key].count = 0;
    }

    // Compute the stats
    for (const ticket of this.$$wallet) {
      if (!this.statusType[ticket.status]) continue;

      this.statusType[ticket.status].count++;
    }

    for (const contract of this.$$contracts) {
      const contractStatus = this._contractStatus(contract);

      switch (contractStatus) {
        case CONTRACT_STATUS.ACTIVE:
          this.statusType['ACTIVE'].count++;
          break;
        case CONTRACT_STATUS.PENDING:
          this.statusType['PENDING'].count++;
          break;
        case CONTRACT_STATUS.REMAINING:
          this.statusType['REMAINING'].count++;
          break;
        case CONTRACT_STATUS.CANCELED:
        case CONTRACT_STATUS.EXPIRED:
          this.statusType['EXPIRED'].count++;
          break;
        case CONTRACT_STATUS.EMPTY:
        default:
          break;
      }

      if (contract.transferredAt) {
        this.statusType['TRANSFER_OUT'].count++;
      }
    }
  }

  public async onChangeCommentNetwork(networkId: string | null) {
    this.$$selectedCommentNetwork = networkId;
    this.$$comments = await this.customersService.$$getComments(this.$$customer.customer_id, {
      networkId,
    });
  }

  private async updateWallet(customerId: string) {
    this.$$loadingWallet = true;

    const tickets = await this.customersService.$$getWallet(customerId);
    // Reset all the stats to 0
    for (const key of Object.keys(this.statusType)) {
      this.statusType[key].count = 0;
    }

    // Compute the stats
    for (const ticket of tickets) {
      if (!this.statusType[ticket.status]) continue;

      this.statusType[ticket.status].count++;
    }

    this.$$wallet = tickets;
    this.$$filteredWallet = this.filterWalletContent(tickets) as $$Ticket[];

    this.$$loadingWallet = false;
  }

  public async toggleCustomerStatus() {
    try {
      let isActive = ['true', '1', 1, true].includes(this.$$customer.active);

      if (isActive) {
        await this.customersService.deactivateCustomer(this.$$customer.customer_id);

        isActive = false;
      } else {
        await this.customersService.reactivateCustomer(this.$$customer.customer_id);

        isActive = true;
      }

      this.$$customer.active = `${isActive}`;

      this.$$profiles = this.$$profiles.map((profile) => {
        if (profile.customer_id === this.$$customer.customer_id) {
          // Boolean(0) === false
          // Boolean(1) === true
          return { ...profile, active: Boolean(isActive) };
        }

        return profile;
      });

      this.isActionOpen = false;
    } catch (error) {
      if (error?.error?.code === 'CUSTOMER_DEACTIVATION_ACTIVE_SUBSCRIPTION_INVALID') {
        this.notification.warn(
          this.translate.instant('pages.customer_details.error_tacit_active'),
          undefined,
          {
            timeOut: 10000,
            pauseOnHover: true,
            clickToClose: true,
          }
        );
        return;
      }
      this.notification.error(this.translate.instant('otherslabels.generic_error'));
    }
  }

  public postComment = async (comment: CommentFormValues) => {
    const requestBody = { text: comment.message, networkId: comment.networkId };
    const customerId = this.$$customer.customer_id;

    const request = await firstValueFrom(
      this.customersService.addCustomerComment(customerId, requestBody)
    );

    if (request.id) {
      this.$$comments = await this.customersService.$$getComments(customerId, {
        networkId: this.$$selectedCommentNetwork,
      });

      return { success: true };
    }

    return { success: false, errorMessage: request.errorMessage };
  };

  public editComment = async (comment: CommentFormValues) => {
    const requestBody = { text: comment.message, networkId: comment.networkId };
    const customerId = this.$$customer.customer_id;

    const request = await this.customersService.editCustomerComment(
      comment.id,
      customerId,
      requestBody
    );

    if (request.id) {
      this.$$comments = await this.customersService.$$getComments(customerId, {
        networkId: this.$$selectedCommentNetwork,
      });

      return { success: true };
    }

    return { success: false, errorMessage: request.errorMessage };
  };

  public deleteComment = async (id: number) => {
    const request = await this.customersService.deleteCustomerComment(String(id));
    const customerId = this.$$customer.customer_id;

    if (request === true) {
      this.$$comments = await this.customersService.$$getComments(customerId, {
        networkId: this.$$selectedCommentNetwork,
      });

      return { success: true };
    }

    return { success: false, errorMessage: request.errorMessage };
  };

  public async cancelTickets() {
    if (!this.areTicketsSelected) return;

    const confirmDelete = confirm(this.translate.instant('pages.customer_details.confirm_delete'));

    if (!confirmDelete) return;

    this.$$loadingWallet = true;
    let tickets = { success: [], failed: [] };
    if (this.activeTicketsTab === 'v1') {
      tickets = await this.ticketV1Cancel(this.$$selectedTicketRows);
    } else {
      tickets = await this.ticketV2Cancel(this.$$selectedTicketRows);
    }

    this.$$loadingWallet = false;
    if (tickets.failed.length) {
      return this.notification.error(
        this.translate.instant('pages.customer_details.error_delete_ticket', {
          failed: tickets.failed.length,
        })
      );
    }

    return this.notification.success(this.translate.instant(`otherslabels.notif_delete_ok`));
  }

  public openAnonymisationModal(): void {
    if (this.$$customer.active === '1' || this.$$customer.anonymized_at) return;

    this.changeDetectorRef.detach();

    const modalRef = this.modal.open(AnonymizationModalComponent, {
      width: '550px',
      data: {
        customerId: this.$$customer.customer_id,
        customerEmail: this.$$customer.email,
        customerAnonymized: this.$$customer.anonymized_at,
      },
    });

    modalRef.afterClosed().subscribe(() => {
      this.changeDetectorRef.reattach();
      this.isActionOpen = false;
    });

    this.router.events.subscribe(() => {
      modalRef.close();
    });
  }

  public async sendResetPasswordEmail() {
    try {
      await this.customersService.sendResetPasswordEmail(this.$$customer.customer_id);

      this.notification.success(
        this.translate.instant('pages.customer_details.reset_password_email_sent')
      );
    } catch (error: any) {
      if (error?.error?.code && error.error.code === ERROR_FIREBASE_RESET_PASSWORD) {
        this.notification.error(
          this.translate.instant('pages.customer_details.error_reset_password_no_firebase'),
          undefined,
          {
            timeOut: 10000,
          }
        );
      } else {
        this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
      }
    } finally {
      this.isActionOpen = false;
    }
  }

  public openUserPhotoModal(isTicketPicture = false) {
    this.changeDetectorRef.detach();

    const modalRef = this.modal.open(ImageUpdateModalComponent, {
      width: '600px',
      disableClose: isTicketPicture,
      data: {
        customerId: this.$$customer.customer_id,
        picture: this.$$customer.picture,
        isTicketPicture,
      },
    });

    modalRef.afterClosed().subscribe(() => {
      this.changeDetectorRef.reattach();
    });

    modalRef.componentInstance.updateField.subscribe((customer) => {
      this.$$customer = customer;
    });
    this.router.events.subscribe(() => {
      modalRef.close();
    });

    return modalRef;
  }

  public openAttributesModal(attributeData: Record<string, any>): void {
    const modalRef = this.modal.open(AttributesModalComponent, {
      width: '450px',
      data: attributeData,
    });

    modalRef.componentInstance.updateField.subscribe((attributes) => {
      this.$$attributes = attributes;

      // Probably force the refresh?
      if (this.$$allAttributes) {
        this.$$allAttributes = [...this.$$allAttributes];
      }
    });

    this.router.events.subscribe(() => {
      modalRef.close();
    });
  }

  public openInformationModal(): void {
    this.changeDetectorRef.detach();

    const modalRef = this.modal.open(InformationModalComponent, {
      width: '450px',
      maxHeight: '100vh',
      data: {
        isCustomerParent: this.isCustomerParent,
        customerId: this.$$customer.customer_id,
        firstname: this.$$customer.firstname,
        lastname: this.$$customer.lastname,
        birthday: this.$$customer.birthday,
        email: this.$$customer.email,
        phone: this.$$customer.phone,
        address: this.$$customer.address,
      },
    });

    modalRef.afterClosed().subscribe(() => {
      this.changeDetectorRef.reattach();
      this.isActionOpen = false;
    });

    modalRef.componentInstance.updateField.subscribe((customer) => {
      this.$$customer = customer;
    });

    this.router.events.subscribe(() => {
      modalRef.close();
    });
  }

  private async openUpdateDocumentModal(documentData) {
    const isDocStatusAcceptable = ['INVALID'].includes(documentData.status);
    if (!isDocStatusAcceptable) return;

    this.changeDetectorRef.detach();

    const modal = this.modal.open(UpdateDocumentModalComponent, {
      width: '600px',
      data: {
        documentTypeId: documentData.type_id,
        userId: this.$$customer.customer_id,
      },
    });

    const success = await firstValueFrom(modal.afterClosed());
    this.changeDetectorRef.reattach();

    if (success) {
      this.retrieveDocuments(+this.$$customer.customer_id);
    }
  }

  private openDeleteDocumentModal(documentData): void {
    const isDocStatusAcceptable = ['VALID', 'INVALID'].includes(documentData.status);
    if (!isDocStatusAcceptable) return;

    this.changeDetectorRef.detach();

    const modalRef = this.modal.open(DeleteDocumentModalComponent, {
      width: '600px',
      data: {
        documentId: documentData.id,
        documentTypeName: documentData.name,
        customerId: this.$$customer.customer_id,
      },
    });

    modalRef.afterClosed().subscribe(() => {
      this.changeDetectorRef.reattach();
    });

    modalRef.componentInstance.updateField.subscribe((documents) => {
      this.handleFilterDocument(documents);
    });

    this.router.events.subscribe(() => {
      modalRef.close();
    });
  }

  public $$openNetworkDissociationModal(networkId: string): void {
    this.changeDetectorRef.detach();

    const modalRef = this.modal.open(NetworkDissociationModalComponent, {
      width: '450px',
      maxHeight: '100vh',
      data: {
        customerId: this.$$customer.customer_id,
        networkId,
      },
    });

    modalRef.afterClosed().subscribe(() => {
      this.changeDetectorRef.reattach();
    });

    modalRef.componentInstance.updateField.subscribe((networkIds) => {
      this.$$networks = this.$$populateNetworks(networkIds);
    });

    this.router.events.subscribe(() => {
      modalRef.close();
    });
  }

  public async openTicketGenerationModal(ticket: $$Ticket = null) {
    this.changeDetectorRef.detach();

    let ticketNetwork = null;

    if (ticket?.network_id) {
      ticketNetwork = this.auth.getNetwork(ticket.network_id);
    }

    // TODO: Document what this does. It doesn't seem very clear on first read.
    const customerNetworks = this.$$customerNetworks.filter((customerNetwork) => {
      return this.activeTicketsTab === 'v1' || customerNetwork.blueprints.length > 0
    });

    const modalRef = this.modal.open(TicketGenerationModalComponent, {
      width: '80vw',
      minHeight: '200px',
      data: {
        customer: this.$$customer,
        customerNetworks,
        version: this.activeTicketsTab,
        transfert: ticketNetwork ? { ticket, network_name: ticketNetwork?.name } : false,
      },
    });

    modalRef.componentInstance.desactivateTicketEvent.subscribe((ticket) => {
      modalRef.componentInstance.ticketGenerationInProcess = true;
      this._desactivateTicket(ticket).then(
        (isDeactivate) => {
          let optionStatus = null;
          switch (isDeactivate) {
            case true:
              optionStatus = 'OK';
              break;
            case false:
              optionStatus = 'KO';
              break;
            default:
              optionStatus = 'WARN';
          }
          modalRef.componentInstance.desactivateTicketOption = optionStatus;
          modalRef.componentInstance.ticketGenerationInProcess = false;
        },
        () => {
          modalRef.componentInstance.desactivateTicketOption = 'KO';
          modalRef.componentInstance.ticketGenerationInProcess = false;
        }
      );
    });

    modalRef.componentInstance.updateField.subscribe((customerData) => {
      const { customer, profiles } = customerData;

      this.$$customer = customer;
      this.$$profiles = profiles;
      this.updateWallet(customer.customer_id);
      this.updateWalletContracts(customer.customer_id);
    });

    modalRef.afterClosed().subscribe(() => {
      this.changeDetectorRef.reattach();
    });

    this.router.events.subscribe(() => {
      modalRef.close();
    });
  }

  public openFusionModal() {
    this.changeDetectorRef.detach();

    const modalRef = this.modal.open(FusionModalComponent, {
      width: '80vw',
      minHeight: '200px',
      data: {
        customer: this.$$customer,
        attributes: {
          global: this.$$allAttributes,
          customer: this.$$attributes,
        },
      },
    });

    modalRef.afterClosed().subscribe(() => {
      this.changeDetectorRef.reattach();
      this.isActionOpen = false;
    });
  }

  public async $$saveNetwork(networkId: string) {
    this.$$newNetWorkLoading = true;

    try {
      const { networkIds } = await this.customersService.linkNetwork(
        Number.parseInt(this.$$customer.customer_id, 10),
        Number.parseInt(networkId, 10)
      );

      this.$$networks = this.$$populateNetworks(networkIds);

      this.$$newNetworkId = '';
      this.$$isAddingNetwork = false;

      this.notification.success(
        this.translate.instant('pages.customer_details.network_association_success_notification')
      );
    } catch (e) {
      switch (e.error?.code) {
        case 'NETWORK_FORBIDDEN':
          this.notification.error(
            this.translate.instant('pages.customer_details.network_forbidden')
          );
          break;
        case 'CUSTOMER_NOT_FOUND':
          this.notification.error(
            this.translate.instant('pages.customer_details.customer_not_found')
          );
          break;
        case 'CUSTOMER_NETWORK_DUPLICATE':
          this.notification.error(
            this.translate.instant('pages.customer_details.network_association_already_exists')
          );
          break;
        default:
          this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
      }
    } finally {
      this.$$newNetWorkLoading = false;
    }
  }

  public resetForm() {
    this.filterForm.reset();
    this.$$filteredWallet = this.$$wallet;
    this.$$filteredContracts = this.$$contracts;
  }

  public resetNetworkFilter() {
    this.filterForm.controls.customerNetworkName.reset();
    this.filterForm.controls.customerNetwork.reset();
  }

  public isVerificationMailsent = false;

  public async sendVerificationMail() {
    this.isVerificationMailsent = true;

    setTimeout(() => {
      this.isVerificationMailsent = false;
    }, 5000);

    try {
      const success = await this.customersService.sendVerificationMail(this.$$customer.customer_id);
      if (!success) throw new Error('UNKNOWN ERROR');

      this.notification.success(this.translate.instant('pages.customer_details.verification_send'));
    } catch (error) {
      switch (error?.error?.code) {
        case 'CUSTOMER_ACCOUNT_ALREADY_VERIFIED':
          return this.notification.error(
            this.translate.instant('pages.customer_details.error_already_verified')
          );
        case 'CUSTOMER_NOT_FOUND':
          return this.notification.error(
            this.translate.instant('pages.customer_details.error_user_not_found')
          );
        case 'CUSTOMER_ACCOUNT_VERIFICATION_WAITING_TIME_NOT_REACHED':
          return this.notification.error(
            this.translate.instant('pages.customer_details.wait_for_send')
          );
        default:
          this.notification.error(
            this.translate.instant('pages.customer_details.error_verification_send')
          );
          break;
      }
    }
  }

  //Lost phone Modal
  public openLostPhoneModal() {
    const lostPhoneModal = this.lostPhoneModal.open(CustomerLostPhoneModalComponent, {
      width: '50vw',
      data: {
        title: this.translate.instant('pages.customer_details.declare_phone_lost'),
        text: `${this.translate.instant(
          'pages.customer_details.lost_phone_disconnect'
        )}<br/><strong>${this.translate.instant(
          'pages.customer_details.confirm_phone_lost'
        )}</strong>`,
      },
    });

    lostPhoneModal.afterClosed().subscribe((result) => {
      if (!result) return;
      this.disconnectUserSessions();
    });
  }

  //Lost Phone feature -- Disconnection
  private async disconnectUserSessions() {
    try {
      const response = await this.customersService.releaseSessions(this.$$customer.customer_id);
      if (response.success) {
        this.notification.success(
          this.translate.instant('pages.customer_details.delete_active_connections')
        );
        return;
      }
    } catch (e) { }
    this.notification.error(
      this.translate.instant('pages.customer_details.error_delete_active_connections')
    );
  }

  public _isTicketCanceled(ticket: Ticket) {
    return ['CANCELED', 'CANCELLED'].includes(ticket.status);
  }

  public _isTicketNotCanceled(ticket: Ticket) {
    return !['CANCELED', 'CANCELLED'].includes(ticket.status);
  }

  private async _desactivateTicket(ticket: $$Ticket) {
    if (
      !this.permissionGuard.isOnePermissionAllowed(['MANAGE_CUSTOMER_WALLET']) ||
      ['CANCELED', 'CANCELLED'].includes(ticket.status)
    ) {
      return false;
    }

    const showSubscriptionCheckbox = Boolean(ticket.subscription_id);

    const dialogRef = this.modal.open(DeleteTicketModalComponent, {
      data: {
        label: ticket.label,
        showSubscriptionCheckbox,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: DeleteTicketModalResponse) => {
      if (result.delete) {
        try {
          const promises: Promise<boolean | void>[] = [this.ticketService.cancelTicket(+ticket.id)];

          if (showSubscriptionCheckbox && result?.deleteLinkedSubscription === true) {
            promises.push(
              this.disableSubscription(ticket.subscription_id, false).catch((error) => {
                // If the subscription is already disabled -> no error
                if (error === 'SUBSCRIPTION_ALREADY_DISABLED') {
                  return;
                }
                throw new Error(error);
              })
            );
          }

          await Promise.all(promises);

          this.updateWallet(this.$$customer.customer_id);
          this.notification.success(this.translate.instant(`otherslabels.notif_delete_ok`));
        } catch {
          this.notification.error(this.translate.instant(`otherslabels.notif_delete_ko`));
        }
      }
    });
  }

  private _contractStatus(
    contract: Contract
  ): (typeof CONTRACT_STATUS)[keyof typeof CONTRACT_STATUS] {
    if (contract.blockedAt || contract.deletedAt) {
      return CONTRACT_STATUS.CANCELED;
    }

    if (!contract.state) {
      return CONTRACT_STATUS.EXPIRED;
    }

    const now = new Date();
    const contractStartDate = new Date(contract.state.contractStartDate);
    const contractEndDate = new Date(contract.state.contractEndDate);
    const validationEndDate = new Date(contract.state.validationEndDate);

    if (contract.state.contractEndDate !== null && compareAsc(contractEndDate, now) === -1) {
      return CONTRACT_STATUS.EXPIRED;
    }

    if (contract.state.contractStartDate !== null && compareAsc(contractStartDate, now) >= 0) {
      return CONTRACT_STATUS.REMAINING;
    }

    if (contract.state.validationStartDate === null) {
      return CONTRACT_STATUS.REMAINING;
    }

    if (contract.state.validationEndDate !== null && compareAsc(validationEndDate, now) >= 0) {
      return CONTRACT_STATUS.PENDING;
    }

    if (
      contract.state.validationRemainingPunches !== null &&
      contract.state.validationRemainingPunches <= 0
    ) {
      return CONTRACT_STATUS.EMPTY;
    }

    return CONTRACT_STATUS.ACTIVE;
  }

  private _isContractCanceled(contract: Contract) {
    return contract.blockedAt !== null;
  }

  private _isContractActive(contract: Contract) {
    return contract.blockedAt === null;
  }

  private async _activateContract(contract: Contract) {
    if (
      !this.permissionGuard.isOnePermissionAllowed(['MANAGE_CUSTOMER_WALLET']) ||
      this._isContractActive(contract)
    ) {
      return;
    }
    const activeContractResponse = await this.customersService.reactivateContract(contract.id);
    if (activeContractResponse) {
      this.updateWalletContracts(this.$$customer.customer_id);
      this.notification.success(this.translate.instant(`otherslabels.notif_reactivate_ok`));
    } else {
      this.notification.error(this.translate.instant(`otherslabels.notif_reactivate_ko`));
    }
  }

  private async _desactivateContract(contract: Contract) {
    const showSubscriptionCheckbox = Boolean(contract.subscriptionId);

    const dialogRef = this.modal.open(DeleteTicketModalComponent, {
      data: {
        label: contract.blueprintName,
        showSubscriptionCheckbox,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: DeleteTicketModalResponse) => {
      if (result.delete) {
        const promises: Promise<any>[] = [this.customersService.disableContract(contract.id)];

        if (showSubscriptionCheckbox && result?.deleteLinkedSubscription === true) {
          promises.push(
            this.disableSubscription(contract.subscriptionId, false).catch((error) => {
              // If the subscription is already disabled -> no error
              if (error === 'SUBSCRIPTION_ALREADY_DISABLED') {
                return;
              }
              throw new Error(error);
            })
          );
        }

        const responses = await Promise.all(promises);

        if (!responses[0]) {
          this.notification.error(this.translate.instant(`otherslabels.notif_delete_ko`));
          return;
        }

        this.updateWalletContracts(this.$$customer.customer_id);
        this.notification.success(this.translate.instant(`otherslabels.notif_delete_ok`));
      }
    });
  }

  private updateWalletContracts(customerId: string) {
    this.$$loadingContracts = true;
    this.customersService
      .getContracts(parseInt(customerId, 10))
      .then((contracts) => {
        this.$$contracts = contracts;
        this.$$filteredContracts = this.filterWalletContent(contracts) as Contract[];
        this.computeContractsStats();
      })
      .finally(() => {
        this.$$loadingContracts = false;
      });
  }

  //Update blueprints list for customer's networks
  private updateCustomerNetworks(networks: Network[]) {
    const networksIds = networks.map((network) => String(network.id));
    this.productsService.getNetworksBlueprints({ network: networksIds }).subscribe({
      next: (response) => {
        this.$$customerNetworks = this.$$networks.map<{
          network: Network;
          blueprints: Blueprint[];
        }>((network) => {
          return {
            network: network,
            blueprints: !response.filter
              ? []
              : response
                .filter((blueprint) => blueprint.networkId === String(network.id))
                .sort((blueprintA, blueprintB) => blueprintA.name.localeCompare(blueprintB.name)),
          };
        });
      },
      error: () => {
        // In the event of an error (e.g. no access to blueprints),
        // no blueprint is placed in customerNetworks.
        this.$$customerNetworks = this.$$networks.map<{
          network: Network;
          blueprints: Blueprint[];
        }>((network) => {
          return {
            network: network,
            blueprints: [],
          };
        });
      },
    });
  }

  private async ticketV1Cancel(selectedTickets) {
    const successfulTickets = [];
    const failedTickets = [];
    for (let ticket of selectedTickets) {
      if (['CANCELED', 'CANCELLED'].includes(ticket.elem.status)) continue;

      const deleteTicketResponse = await this.ticketService.cancelTicket(ticket.elem.id);

      if (deleteTicketResponse) {
        successfulTickets.push(ticket.elem.id);
      } else failedTickets.push(ticket.elem.id);
    }

    if (successfulTickets.length) {
      const tickets = await this.customersService.$$getWallet(this.$$customer.customer_id);
      // Reset all the stats to 0
      for (const key of Object.keys(this.statusType)) {
        this.statusType[key].count = 0;
      }

      // Compute the stats
      for (const ticket of tickets) {
        if (!this.statusType[ticket.status]) continue;

        this.statusType[ticket.status].count++;
      }

      this.$$wallet = tickets;
      this.$$filteredWallet = this.filterWalletContent(tickets) as $$Ticket[];
    }

    return {
      success: successfulTickets,
      failed: failedTickets,
    };
  }

  private async ticketV2Cancel(selectedContracts) {
    const successfulContracts = [];
    const failedContracts = [];
    for (let contract of selectedContracts) {
      if (contract.elem.blockedAt) continue;

      const deleteContractResponse = await this.customersService.disableContract(contract.elem.id);
      if (deleteContractResponse) {
        successfulContracts.push(contract.elem.id);
      } else {
        failedContracts.push(contract.elem.id);
      }
    }
    this.updateWalletContracts(this.$$customer.customer_id);

    return {
      success: successfulContracts,
      failed: failedContracts,
    };
  }

  private retrieveCustomerOrders(customerId: string, status?: CustomerOrderStatus | null) {
    this.customersService.getCustomerOrders(customerId, status).then((orders) => {
      this.customerOrders = [];
      if (!orders) return;

      for (const order of orders) {
        for (const article of order.articles) {
          if (!article?.recipient || +article.recipient.id === +this.$$customer.customer_id) {
            article.recipient = {
              id: null,
              lastName: this.$$customer.lastname,
              firstName: this.$$customer.firstname,
            };
          }

          const network = this.auth.networks.find((network) => +network.id == +order.networkId);

          this.customerOrders.push({
            productId: article.productId,
            productName: article.productName,
            purchaseDate: order.purchaseDate,
            price: article.price,
            orderId: order.id,
            orderCode: order.code,
            recipient: article.recipient,
            network: network || `${order.networkId}`,
            status: order.status,
            reservation: article.reservation,
            quantity: article.quantity,
          });
        }
      }

      if (!status) {
        this.showCustomerOrders = this.customerOrders.length > 0;
      }

      this.customerOrders.sort((orderA, orderB) =>
        orderA.purchaseDate > orderB.purchaseDate ? -1 : 1
      );
    });
  }

  public computeOrderHistoryFilter() {
    const { status } = this.filterOrderHistory.getRawValue();
    this.retrieveCustomerOrders(this.$$customer.customer_id, status);
  }

  private async retrieveSubscriptions(customerId: string): Promise<void> {
    const parentProfile = this.$$profiles.find((profile) => profile.isParent);
    if (!parentProfile) return;

    this.$$loadingSubscriptions = true;

    const subscriptions = await this.customersService.$$getSubscriptions(
      parentProfile.customer_id,
      customerId
    );

    this.$$subscriptions = subscriptions;
    this.$$loadingSubscriptions = false;
  }

  private handleFilterDocument(documents: Document[]): void {
    const [activeDocuments, deletedDocuments] = documents.reduce(
      ([activeDocuments, deletedDocuments], document) => {
        // A document is considered "deleted" if :
        // - it has been soft-deleted
        // - its file has been soft-deleted
        // - it has no file
        if (document.deleted_at || document.file_deleted_at) {
          deletedDocuments.push(document);
        } else {
          activeDocuments.push(document);
        }

        return [activeDocuments, deletedDocuments];
      },
      [[], []]
    );

    this.$$documents = activeDocuments;
    this.$$deletedDocuments = deletedDocuments;
  }

  private async _updateCMSUserList() {
    //Get list of users from contracts and wallets
    const ticketsCmsUserIds: number[] = this.$$wallet.map((ticket) => +ticket.cms_user_id);
    const contractsCmsUserIds: number[] = this.$$contracts.map((contract) => +contract.operatorId);
    let cmsUserIds = [...new Set(ticketsCmsUserIds.concat(contractsCmsUserIds))];
    //Get user via API if needed
    for (const cmsUserId of cmsUserIds) {
      if (!cmsUserId || this._cmsUsers.find((user) => user.id === cmsUserId)) continue;
      const user = await firstValueFrom(this.userService.getUserDetail(cmsUserId));
      this._cmsUsers.push(user);
    }
  }

  private _walletOriginLabel(contractTicket: { contract?: Contract; ticket?: Ticket }): string {
    const { contract, ticket } = contractTicket;
    if (contract?.orderCode) return contract.orderCode;
    if (ticket?.order_id && ticket?.order_identifier) return ticket.order_identifier;

    let userId: string = null;
    if (contract?.operatorId) userId = contract.operatorId;
    if (ticket?.cms_user_id) userId = ticket.cms_user_id;

    if (!userId) return this.translate.instant('pages.customer_details.wallet_unknown_origin');

    const user = this._cmsUsers.find((user) => +user.id === +userId);
    const userLabel = !user
      ? `${this.translate.instant('pages.customer_details.user_id')}${userId}`
      : `${user.firstname} ${user.lastname}`;

    return this.translate.instant('pages.customer_details.ticket_generated_by', {
      label: userLabel,
    });
  }

  private _walletOriginLink(contractTicket: { contract?: Contract; ticket?: Ticket }): string[] {
    const { contract, ticket } = contractTicket;
    if (ticket?.order_id) return ['/orders', ticket.order_id];
    if (ticket?.cms_user_id) return ['/users', ticket.cms_user_id];
    if (contract?.orderId) return ['/orders', contract.orderId];
    if (contract?.operatorId) return ['/users', contract.operatorId];
    return null;
  }

  public get isCheckAllowed() {
    return (
      !!!this.$$customer.verified_at &&
      this.$$customer.active === '1' &&
      !!!this.$$customer.anonymized_at &&
      !this.$$loadingCheckAccount &&
      this.isCustomerParent
    );
  }

  public $$loadingCheckAccount = false;

  public async accountCheck() {
    this.$$loadingCheckAccount = true;
    const response = await this.customersService.checkAccount(this.$$customer.customer_id);
    this.$$loadingCheckAccount = false;
    this.changeDetectorRef.reattach();
    this.isActionOpen = false;
    if (response.success) {
      this.notification.success(
        this.translate.instant('pages.customer_details.account_check_validation_success')
      );
      this.$$customer.verified_at = new Date().toISOString();
      return;
    }
    this.notification.error(
      this.translate.instant('pages.customer_details.account_check_validation_error'),
      this.translate.instant(response?.error?.message)
    );
  }

  public computeBookingFilters(): void {
    const { customerNetwork, customerQuery } = this.filterForm.value;

    this.bookingFilters = {
      customerId: this.customerId,
      networkId: customerNetwork,
      query: customerQuery,
    };
  }

  private getHtmlBelongingCell(booking: ArticleBooking | undefined): string {
    if (booking) {
      return `
      <p><strong>${this.translate.instant('pages.order_details.origin')} :</strong> ${booking.tripDepartureLocationLabel
        }</p>
      <p><strong>${this.translate.instant('pages.order_details.destination')} :</strong> ${booking.tripArrivalLocationLabel
        }</p>
      <p><strong>${this.translate.instant(
          'pages.order_details.departure_date'
        )} :</strong> ${this.datePipe.transform(booking.tripDepartureDate, 'medium')}</p>
      <p><strong>${this.translate.instant(
          'pages.order_details.arrival_date'
        )} :</strong> ${this.datePipe.transform(booking.tripArrivalDate, 'medium')}</p>
      `;
    }
  }

  private async disableSubscription(subscriptionId: number, handleError: boolean = true) {
    await this.subscriptionService.disableSubscription(subscriptionId, handleError);

    // Update data
    // HACK: The table component doesn't update when only one element is updated, so I have to retrieve the list to update the table.
    // It's not pretty, but I couldn't find any other solution.
    this.retrieveSubscriptions(this.$$customer.customer_id);
  }

  public openDocumentUploadModal(): void {
    const modalRef = this.modal.open(DocumentUploadModalComponent, {
      width: '600px',
      data: {
        customerId: this.customerId,
      },
    });

    modalRef.afterClosed().subscribe((response) => {
      if (response?.success) {
        this.retrieveDocuments(+this.customerId);
      }
    });

    this.router.events.subscribe(() => {
      modalRef.close();
    });
  }

  public retrieveDocuments(customerId: number): void {
    this.customersService
      .$$getDocuments(`${customerId}`)
      .then(async (documents) => {
        this.handleFilterDocument(documents);
      })
      .catch(({ error }) => {
        switch (error.code) {
          case 'CUSTOMER_NOT_FOUND':
            return this.router.navigate(['/404']);
          case 'CUSTOMER_FORBIDDEN':
            return this.router.navigate(['/403']);
          default:
            return this.router.navigate(['/500']);
        }
      })
      .finally(() => {
        this.$$loadingDocuments = false;
      });
  }
}
