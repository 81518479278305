import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from '../modules/shared/services/auth.service';
import { environment as env } from '@env/environment';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router, private readonly auth: AuthService) {}

  private isInError = false;

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const token = this.auth.token;
    let headers = request.headers;

    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    const modifiedRequest = request.clone({ headers, withCredentials: true });

    return next.handle(modifiedRequest).pipe(
      tap((event: any) => {
        // FIXME: We should check if event is an instance of HttpResponse
        this.auth.checkLoggedIn(event.response);
      }),
      catchError(async (response: HttpErrorResponse) => {
        const code = response?.error?.error?.code;

        /**
         * The session is considered scuffed if:
         * - The token is invalid
         * - The session is expired
         **/
        const isSessionScuffed = ['TOKEN_INVALID', 'SESSION_EXPIRED'].includes(code);

        // If the error isn't tied to the session, go on with your life
        if (!isSessionScuffed) {
          throw response.error;
        }

        // Otherwise, show up the session expired modal
        try {
          const action = await this.auth.sessionTimeoutModal();

          if (action === 'REDIRECT') {
            this.auth.clearStorage();
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.auth.redirectUrl } });
            return throwError(() => response?.message ?? 403);
          }
        } catch (error) {
          console.info('Error while handling session timeout', error);

          this.auth.clearStorage();
          const authUrl = new URL(env.config.newAuthenticationUrl);
          authUrl.searchParams.set('redirect', this.auth?.redirectUrl ?? window.location.href);
          location.href = authUrl.toString();
        }
      }),
      map((response: any) => {
        const body = response?.body;

        if (body?.status === 'ko') {
          const code = body?.response?.errorCode;
          const message = body?.response?.errorMessage;

          throw new Error(code ?? message);
        }

        return response;
      })
    );
  }
}
