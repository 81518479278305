import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NetworkFeatureHandle } from '../models/network';

@Injectable()
export class FeatureGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    const data = route.data.feature as {
      handle: string;
      redirectTo: string;
    };
    if (typeof data === 'undefined') return true;

    const hasFeature = this.hasFeature(data.handle);

    if (hasFeature) {
      return true;
    }

    if (data.redirectTo) {
      this.router.navigate([data.redirectTo]);
    }

    return false;
  }

  hasFeature(handle: string): boolean {
    if (Object.keys(NetworkFeatureHandle).includes(handle)) {
      return this.authService.hasFeature(handle as NetworkFeatureHandle);
    } else {
      throw new Error(`Unknown ${handle} feature`);
    }
  }
}
