import { ArticleBooking } from '@app/modules/orders/models/order';
import { Address } from '@app/modules/shared/models/address';
import { Filters } from '@app/modules/shared/models/filters';
import { Ticket } from '@app/modules/shared/models/ticket';
import { Voucher } from '@app/modules/shared/models/voucher';
import { SubscriptionDetails } from '@app/modules/subscriptions/models/subscription.interface';

export interface CustomerComment {
  id: number;
  user_id: number;
  author_id: number;
  network_id: number;
  text: string;
  created_at: string;
  deleted_at?: any;
}

export interface Media {
  id: string;
  code: string;
  type: string;
  user_id: string;
  network_id: string;
  provider_id: string;
  created_at: string;
}

export interface Customer {
  customer_id?: string;
  network_ids?: number[];
  firstname?: string;
  lastname?: string;
  fullname?: string;
  email?: string;
  phone?: string;
  address?: Address;
  picture?: string;
  date?: string; // date
  birthday?: string;
  fb_url?: string;
  documents?: Document[];
  medias?: Media[];
  subscriptions?: SubscriptionDetails[];
  wallet?: Ticket[];
  parent?: Customer;
  profiles?: Customer[];
  isParent?: boolean;
  status?: string;
  universal?: boolean;
  active?: any;
  vouchers?: Voucher[];
  attributes?: Attribute[];
  provider?: 'airweb' | 'sncf';
  anonymized_at?: string;
  verifiedAt?: string;
  authLastAsGuest?: boolean;
}

export interface CustomerFusion {
  id: number;
  firstName: string;
  lastName: string;
  birthdate: string;
  email: string;
  phone: string;
  address?: {
    lines?: string[];
    city?: string;
    zip?: string;
    state?: string;
  };
  attributeValues?: {
    attributeId?: number;
    attributeKey?: string;
    attributeType?: string;
    attributeNetworkId?: number;
    value?: string;
  }[];
  updatedAt: string;
}

export interface CustomerFusionConfiguration {
  email: 'FORMER' | 'RECIPIENT';
  birthdate: 'FORMER' | 'RECIPIENT';
  phone: 'FORMER' | 'RECIPIENT';
  address: 'FORMER' | 'RECIPIENT';
  attributeValues?: {
    [key: number]: 'FORMER' | 'RECIPIENT';
  };
}

export interface Document {
  id: string;
  deleted_at: string;
  file_deleted_at: string;
  expires_at: string;
  examined_at: string;
  label: string;
  name: string;
  status: string;
  type_id: string;
  url: string;
  file_id?: string;
  file_label?: string;
}

export interface Attribute {
  key: string;
  network_id: number;
  type: string;
  value: any;
  label?: string;
}

export interface SetCustomerAttributeResponse {
  response: {
    errorMessage?: string;
    attribute_id: number;
    id: number;
    user_id: number;
    value: any;
  };
}

export interface AnonymizeResponse {
  response: {
    errorMessage?: string;
    errors: {
      code: number;
      message: string;
    }[];
    customers: number[];
  };
}

export interface SendResetPasswordEmailResponse {
  response: {
    errorMessage?: string;
    error: {
      code: number;
      message: string;
    }[];
    success: boolean;
  };
}

export interface UpdateInfoResponse {
  response: {
    errorMessage?: string;
    error: {
      code: number;
      message: string;
    }[];
    success: boolean;
  };
}

export interface CustomerFilters extends Filters {
  provider?: string;
  attribute?: number;
  attribute_search?: string;
}

export interface Contract {
  blockedAt?: string;
  blueprintId: string;
  blueprintName: string;
  code?: string;
  createdAt: string;
  customerId: string;
  data?: string;
  deletedAt?: string;
  endingAt?: string;
  id: string;
  networkId?: string;
  network?: string;
  owner?: string;
  startingAt?: string;
  state: ContractState;
  updatedAt: string;
  vendor: string;
  transferredAt?: string;
  orderId: string;
  orderCode: string;
  operatorId: string;
  trip?: ContractTrip;
  subscriptionId: number | null;
}

export interface ContractState {
  contractEndDate: string;
  contractStartDate: string;
  inspectionEndDate: string;
  validationEndDate: string;
  validationRemainingPunches: number;
  validationRemainingTransfers: number;
  validationStartDate: string;
  validationType: string;
}

export interface ContractTrip {
  origin: {
    url: string;
    label: string;
  };
  destination: {
    url: string;
    label: string;
  };
}

export enum ContractStatus {
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  REMAINING = 'REMAINING',
  PENDING = 'PENDING',
  EMPTY = 'EMPTY',
  ACTIVE = 'ACTIVE',
}

export interface Transfer {
  id: string;
  status: string;
  networkId: number;
  issuerCustomerId: string;
  recipientCustomerId: string;
  providerId: number;
  providerSlug: string;
  contractLabel: string;
  contractCode: string;
  contractType: string;
  updatedAt: string;
  createdAt: string;
  direction?: 'IN' | 'OUT';
  issuerCustomerLabel?: string;
  recipientCustomerLabel?: string;
}

export interface TransfersHistory {
  customers: [
    {
      [key: string]: {
        id: string;
        avatarUrl: string;
        firstName: string;
        lastName: string;
        email: string;
      };
    }
  ];
  transfers: [Transfer];
}

export interface CustomerArticle {
  id: string;
  productId: string;
  productName: string;
  price: number;
  recipient: {
    id: string;
    firstName: string;
    lastName: string;
  };
  origin: string;
  destination: string;
  reservation: ArticleBooking | null;
  quantity: number;
}
export interface CustomerOrders {
  id: string;
  code: string;
  purchaseDate: string;
  customerId: string;
  networkId: number;
  articles: CustomerArticle[];
  status: CustomerOrderStatus;
}

export enum CustomerOrderStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  REGULARIZATION = 'REGULARIZATION',
  TO_REFUND = 'TO_REFUND',
}

export interface Session {
  originType: SessionOriginType | null;
  originAgent: string | null;
  date: string;
  active: boolean;
}

export enum SessionOriginType {
  APP = 'APP',
  ESHOP = 'ESHOP',
  SCHOOL = 'SCHOOL',
  RETAIL = 'RETAIL',
}

export interface CustomerValidation {
  clientSlug: string;
  isTransfer: boolean;
  itemId: number;
  itemLabel: string;
  lineCode: null;
  mediaId: null;
  mediaType: null;
  networkId: number;
  occuredAt: string;
  passengers: number;
  provider: string;
  status: string;
  stopCode: string;
  ticketId: number;
  tripDestinationLabel: string;
  tripOriginLabel: string;
  universal: boolean;
  validationNetworkId: number;
  vehicleCode: string;
  vehicleId: number;
}

export interface CustomerWalletEvent {
  id: string;
  contractId: string;
  type: CustomerWalletEventType;
  contractNetworkId: string;
  passengers: number;
  data: { [key: string]: any };
  occurredAt: string;
  createdAt: string;
  location: {
    lineCode: string;
    lineName: string;
    linePicto: string;
    mediaCode: string;
    mediaType: string;
    stationCode: string;
    stationName: string;
    vehicleCode: string;
    vehicleType: string;
    tripDirection: string;
  };
}

export enum CustomerWalletEventType {
  PUNCH = 'PUNCH',
  TRANSFER = 'TRANSFER',
  CHECK = 'CHECK',
}
