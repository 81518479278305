import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDocument } from '@app/modules/orders/models/document';
import { NetworkPaymentOptions, PayzenOptions } from '@app/modules/payment/models/commission';
import { GenericResponse } from '@app/modules/shared/models/generic-response';
import { AuthService } from '@app/modules/shared/services/auth.service';
import { CacheManager } from '@app/utils/cache-manager';
import { environment as env } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingInfo, Domain, NetworkInfo, NetworkProvider } from '../models/network-info';
import { NetworkFeature, NetworkFeatureHandle } from '@app/modules/shared/models/network';

@Injectable()
export class NetworkService {
  private cache = new CacheManager('network');

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notification: NotificationsService,
    private readonly translate: TranslateService
  ) {}

  public getNetworks({ withId = false }): Observable<NetworkInfo[]> {
    let version = this.cache.getVersion();
    const file = withId ? `Network/getNetworks?withId=true&v=` : `Network/getNetworks?v=`;

    return this.http
      .get<GenericResponse<{ networks: NetworkInfo[] }>>(env.config.feedRoot + file + version)
      .pipe(
        map(({ response }) => {
          return response.networks;
        })
      );
  }

  public getNetworksDetails(id: number): Observable<NetworkInfo> {
    const version = this.cache.getVersion();

    return this.http
      .get<GenericResponse<{ networks: NetworkInfo[] }>>(
        env.config.feedRoot + 'Network/getNetworks',
        {
          params: { id, version },
        }
      )
      .pipe(
        map(({ response }) => {
          return response.networks[0];
        })
      );
  }

  public updateLocales(
    networkId: number | string,
    localesAsArray: { locale: string; equivalences: string[] | null }[]
  ) {
    const locales = localesAsArray.reduce((locales, { locale, equivalences }, index) => {
      if (!locale) return locales;

      locales[locale] = {
        equivalences: equivalences?.length ? equivalences : null,
        default: index === 0,
        priority: index + 1,
      };

      return locales;
    }, {});

    const response$ = this.http
      .put<GenericResponse>(`${env.config.feedRoot}Network/updateLocales`, { networkId, locales })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            throw new Error(response.errorMessage);
          }

          return response.success;
        })
      );

    return firstValueFrom(response$);
  }

  public updateNetwork(networkInfo: NetworkInfo, id: number, translations: any) {
    if ('subscriptions' in networkInfo.configuration) {
      // @ts-expect-error: The update feed that a different type than the get feed
      networkInfo.configuration.has_subscriptions = networkInfo.configuration.subscriptions;
    }

    if ('data' in networkInfo && networkInfo.data !== null) {
      networkInfo.data = JSON.parse(networkInfo.data);
    }

    return this.http
      .post<GenericResponse<{ success: boolean }>>(env.config.feedRoot + `Network/editNetworks`, {
        networkInfo,
        id,
        translations,
      })
      .pipe(
        map(({ response }) => {
          this.cache.incrementVersion();

          if (response.errorMessage) {
            throw new Error(response.errorMessage);
          }

          return response.success;
        })
      );
  }

  public createNetwork(name: string): Observable<{ id: number }> {
    return this.http
      .post<GenericResponse<{ id: number }>>(env.config.feedRoot + `Network/createNetwork`, {
        name,
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            if (response.errorCode !== null) throw new Error(response.errorCode);
          } else {
            this.cache.incrementVersion();
            return response;
          }
        })
      );
  }

  public getDocuments(networkId: number | string): Observable<IDocument[]> {
    return this.http
      .get<GenericResponse>(env.config.feedRoot + `Network/getDocuments`, {
        params: {
          networkId,
        },
      })
      .pipe(
        map(({ response }) => {
          return response.documents;
        })
      );
  }

  public getMails(): Observable<any> {
    return this.http.get<GenericResponse>(env.config.feedRoot + `Network/getMails`).pipe(
      map(({ response }) => {
        return response.emails;
      })
    );
  }

  public getTags(): Observable<any> {
    return this.http.get<GenericResponse>(env.config.feedRoot + `Network/getTags`).pipe(
      map(({ response }) => {
        return response.tags;
      })
    );
  }

  public setNetworkMailTags(datas: any): Observable<any> {
    return this.http.post<any>(env.config.feedRoot + `Network/setNetworkMailTags`, datas).pipe(
      map(({ response }) => {
        return response.success;
      })
    );
  }

  public editNetworkClients(networkId: string, isTixiPass: boolean, clients: number[]) {
    const body = { networkId, isTixiPass, clients };

    return this.http
      .post<GenericResponse>(`${env.config.feedRoot}Network/editNetworkClients`, body)
      .pipe(map(({ response }) => response));
  }

  public getBillingInfo(networkId: string) {
    return this.http
      .get<GenericResponse<{ billingInfos: BillingInfo[] }>>(
        env.config.feedRoot + 'Network/getBillingInfo',
        {
          params: { networkId },
        }
      )
      .pipe(map(({ response }) => response.billingInfos));
  }

  public addBillingInfo(networkId: string, info: Partial<BillingInfo>) {
    const body = { networkId: +networkId, ...info };
    return this.http
      .post<GenericResponse>(`${env.config.feedRoot}Network/addBillingInfo`, null, {
        params: body,
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
            throw new Error(response.errorCode);
          } else {
            this.notification.success(
              this.translate.instant('pages.network_details.billing_info_added_notification')
            );
            return response;
          }
        })
      );
  }

  public updateBillingInfo(info: BillingInfo) {
    return this.http
      .put<GenericResponse>(`${env.config.feedRoot}Network/updateBillingInfo`, null, {
        params: { ...info },
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
            throw new Error(response.errorCode);
          } else {
            this.notification.success(
              this.translate.instant('pages.network_details.billing_info_updated_notification')
            );
            return response;
          }
        })
      );
  }

  public deleteBillingInfo(id: number) {
    //id = billing field id
    return this.http
      .delete<GenericResponse>(`${env.config.feedRoot}Network/removeBillingInfo`, {
        params: { id },
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            this.notification.error(this.translate.instant('pages.customer_details.generic_error'));
            throw new Error(response.errorCode);
          } else {
            this.notification.success(
              this.translate.instant('pages.network_details.billing_info_deleted_notification')
            );
            return response;
          }
        })
      );
  }

  public getDomains(networkId: number) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ domains: Domain[] }>>(`${env.config.feedRoot}Network/getDomains`, {
          params: { networkId },
        })
        .pipe(
          map(({ response }) => {
            return response.domains;
          })
        )
    );
  }

  public addDomain(domainInfo: { networkId: string | number; domain: string; default: boolean }) {
    return firstValueFrom(
      this.http
        .post<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Network/addDomain`,
          domainInfo
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public editDomain(domainInfo: { networkId: string | number; domain: string; default: boolean }) {
    return firstValueFrom(
      this.http
        .put<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Network/editDomain`,
          domainInfo
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public deleteDomain(networkId: string | number, domain: string) {
    return firstValueFrom(
      this.http
        .delete<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Network/deleteDomain`,
          {
            params: { networkId, domain },
          }
        )
        .pipe(
          map(({ response }) => {
            return response;
          })
        )
    );
  }

  public getProviders(networkId, onlyTicketing = false) {
    return firstValueFrom(
      this.http
        .get<GenericResponse<NetworkProvider[]>>(`${env.config.feedRoot}Network/providers`, {
          params: { networkId, onlyTicketing },
        })
        .pipe(
          map(({ response }) => {
            if (response.errorMessage) {
              throw new Error(response.errorCode);
            } else {
              return response;
            }
          })
        )
    );
  }

  public updateNetworkProviders(networkId: number, providerIds: number[]) {
    return firstValueFrom(
      this.http
        .put<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Network/updateProviders`,
          {
            networkId,
            providerIds,
          }
        )
        .pipe(
          map(({ response }) => {
            if (response.errorMessage) {
              throw new Error(response.errorCode);
            } else {
              return response;
            }
          })
        )
    );
  }

  public getNetworksPaymentOptions(network_id: number): Observable<NetworkPaymentOptions> {
    let version = this.cache.getVersion();
    return this.http
      .get<GenericResponse<NetworkPaymentOptions>>(
        env.config.feedRoot + 'Payment/getPaymentOptions',
        {
          params: { network_id, v: version },
        }
      )
      .pipe(
        map(({ response }) => {
          return response;
        })
      );
  }

  //THIS IS WAR : Flux delete field configuration if empty value... Need to send them ALL :(
  public updateNetworksPayzenOptions(
    network_id: number,
    options: {
      saveCards: boolean;
      payment_company: string;
      paymentMethods: string[];
      payzen: PayzenOptions;
    }
  ): Observable<any> {
    return this.http
      .post<GenericResponse>(env.config.feedRoot + `Payment/setPaymentOptions`, {
        network_id,
        options: {
          saveCards: options.saveCards ? '1' : '0',
          payment_company: options.payment_company,
          paymentMethods: options.paymentMethods,
          payzen: {
            shop: options.payzen.shop_id,
            certTest: options.payzen.cert_test,
            certProd: options.payzen.cert_prod,
            descriptor: options.payzen.descriptor,
            transferEnabled: options.payzen.transfer_enabled,
            transferDayOfMonth: options.payzen.transfer_day_of_month,
            subscriptionEnabled: options.payzen.subscription_enabled,
            subscriptionAsMultiplePayment: options.payzen.subscription_as_multiple_payment,
          },
        },
      })
      .pipe(
        map(({ response }) => {
          if (response.errorMessage) {
            if (response.errorCode !== null) throw new Error(response.errorCode);
          } else {
            this.cache.incrementVersion();
            return response;
          }
        })
      );
  }

  public updateExportDatasets(
    networkId: number,
    datasetsKeys: string[]
  ): Promise<{ success: boolean }> {
    return firstValueFrom(
      this.http
        .put<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Network/updateExportDatasets`,
          {
            networkId,
            datasetHandles: JSON.stringify(datasetsKeys),
          }
        )
        .pipe(
          map(({ response }) => {
            if (response.errorMessage) {
              throw new Error(response.errorCode);
            } else {
              return response;
            }
          })
        )
    );
  }

  public getNetworkFeatures(networkId: number): Promise<NetworkFeature[]> {
    return firstValueFrom(
      this.http
        .get<GenericResponse<{ features: NetworkFeature[] }>>(
          `${env.config.feedRoot}Network/getNetworkFeatures`,
          {
            params: {
              networkId,
            },
          }
        )
        .pipe(map(({ response }) => response.features))
    );
  }

  public activateFeature(networkId: number, featureHandle: NetworkFeatureHandle): Promise<string> {
    return firstValueFrom(
      this.http
        .post<GenericResponse<{ activatedFeature: string }>>(
          `${env.config.feedRoot}Network/activateFeature`,
          {
            networkId,
            featureHandle,
          }
        )
        .pipe(map(({ response }) => response.activatedFeature))
    );
  }

  public deactivateFeature(
    networkId: number,
    featureHandle: NetworkFeatureHandle
  ): Promise<boolean> {
    return firstValueFrom(
      this.http
        .delete<GenericResponse<{ success: boolean }>>(
          `${env.config.feedRoot}Network/deactivateFeature`,
          {
            params: {
              networkId,
              featureHandle,
            },
          }
        )
        .pipe(map(({ response }) => response.success))
    );
  }
}
